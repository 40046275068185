import { gql, useSubscription } from '@apollo/client';
import React from 'react';
import { CORE_PARTNER } from '../fragments/partners';
import { LoLPartnerQueryVariables } from '../queries/partners';
import { LoLPartner, QueueType } from '../types/lol/partner.type';
import { FetchActionType } from '../types/ReducerTypes';
import { Nullable } from '../types/UtilTypes';

export const SUBSCRIPTION_PARTNER = gql`
  subscription OnUpdatedPartner(
    $tier: String!
    $region: String!
    $position: String!
    $queueType: QueueType!
  ) {
    partnerUpdated(
      tier: $tier
      region: $region
      position: $position
      queueType: $queueType
    ) {
      action
      partner {
        ...CorePartner
      }
    }
  }
  ${CORE_PARTNER}
`;

export type LoLPartnerChangeSet = {
  action: FetchActionType;
  partner: LoLPartner;
};

export function usePartnerUpdated(
  variables: LoLPartnerQueryVariables
): Nullable<LoLPartnerChangeSet> {
  const [changeSet, setChangeSet] =
    React.useState<Nullable<LoLPartnerChangeSet>>(null);

  const { data } = useSubscription<{
    partnerUpdated: LoLPartnerChangeSet;
  }>(SUBSCRIPTION_PARTNER, {
    variables: {
      ...variables,
      queueType:
        variables.queueType === ('EVENT' as QueueType)
          ? 'event'
          : variables.queueType,
    },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (!data || !data.partnerUpdated) return;

    setChangeSet(data.partnerUpdated);
  }, [data]);

  return changeSet;
}

import {
  gql,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import { MemberV2 } from '../types/MemberTypes';
import { useEffect } from 'react';
import { useGlobalState } from '../hooks/states';

export const GET_MEMBER_V2 = gql`
  query {
    meV2 {
      user {
        id
        mid
        nick
        email
        adFree
        planIds
      }
    }
  }
`;

interface Response {
  user: MemberV2 | null;
  getMember: LazyQueryExecFunction<
    { meV2: { user: MemberV2 } },
    OperationVariables
  >;
}

export const useMember = (): Response => {
  const [{ user }, setGlobalState] = useGlobalState();
  const [getMember, { data, ...rest }] = useLazyQuery<{
    meV2: { user: MemberV2 };
  }>(GET_MEMBER_V2);

  useEffect(() => {
    if (!data) return;
    setGlobalState({ user: data.meV2.user });
  }, [data]);

  return {
    user,
    getMember,
    ...rest,
  };
};

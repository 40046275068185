import useSWR from 'swr';
import axios from 'axios';
import { useUser } from './user';

export const useZendeskJWT = () => {
  const { userToken } = useUser();
  const { data, ...rest } = useSWR<string>(
    userToken ? `https://member-api.op.gg/v2/members/me/zendesk-jwt` : null,
    (url: string) =>
      axios
        .get(url, {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => res.data?.data?.token)
        .catch((err) => console.error(err)),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  return {
    jwt: data ?? null,
    ...rest,
  };
};

import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  position: 'left' | 'right';
  disabled?: boolean;
};

const QwerBanner: React.FC<Props> = ({ className, position, disabled }) => {
  return (
    <div
      className={classNames(className, {
        '-mb-2': !!disabled,
      })}
    >
      {!disabled && (
        <a
          href={`https://qwer.gg?utm_source=duo-lol&utm_medium=banner&utm_content=${position}`}
          rel="noopener"
        >
          <img
            src="//opgg-desktop-data.akamaized.net/download/duo/images/banners/banner-qwer-gg.png"
            width={300}
            height="auto"
            alt="QWER.GG banner"
            className="rounded-sm"
          />
        </a>
      )}
    </div>
  );
};

export default QwerBanner;

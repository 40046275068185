import { gql, useQuery } from '@apollo/client';
import { UserNotification } from '../types/notification.type';
import { useEffect, useMemo, useState } from 'react';
import { useNotificationUpdated } from '../subscriptions/notifications';
import { FetchActionType } from '../types/ReducerTypes';
import { useGlobalState } from '../hooks/states';
import { useSetRecoilState } from 'recoil';
import { notificationsState } from '../atoms/notification';

const getTemplateTheme = (templateType: string) => {
  if (templateType.startsWith('BAN_')) {
    return 'bans';
  } else if (templateType.startsWith('WARNING_')) {
    return 'warnings';
  } else {
    return 'defaults';
  }
};

export interface Notifications {
  defaults: UserNotification[];
  bans: UserNotification[];
  warnings: UserNotification[];
}

export const defaultNotifications = {
  defaults: [],
  bans: [],
  warnings: [],
};

export function useNotifications() {
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const setGlobalNotifications = useSetRecoilState(notificationsState);

  const { data, error, loading } = useQuery<{
    notifications: UserNotification[];
  }>(GET_NOTIFICATIONS);
  const changeSet = useNotificationUpdated();

  useEffect(() => {
    if (!data?.notifications) return;

    setNotifications(data?.notifications);
  }, [data]);

  useEffect(() => {
    if (!changeSet) return;

    switch (changeSet.action) {
      case FetchActionType.ADDED:
        setNotifications([changeSet.notification, ...notifications]);
        return;
      case FetchActionType.UPDATED:
        const updatedIndex = notifications.findIndex(
          (noti) => noti.id === changeSet.notification.id
        );
        const entries = JSON.parse(JSON.stringify(notifications));
        entries[updatedIndex] = changeSet.notification;
        setNotifications(entries);
        return;
      case FetchActionType.DELETED:
        setNotifications(
          notifications.filter((noti) => noti.id !== changeSet.notification.id)
        );
        return;
    }
  }, [changeSet]);

  useEffect(() => {
    const _notifications: Notifications = { ...defaultNotifications };

    for (const noti of notifications) {
      const templateTheme = getTemplateTheme(noti.templateType);
      _notifications[templateTheme] = [..._notifications[templateTheme], noti];
    }
    setGlobalNotifications(_notifications);
  }, [notifications]);

  return {
    count: notifications.reduce((acc, cur) => {
      if (!cur.readAt) return ++acc;
      return acc;
    }, 0),
    error,
    loading,
  };
}

export function useNotificationCount() {
  const { data, refetch, error, loading } = useQuery<{
    countNotifications: number;
  }>(GET_UNREAD_NOTIFICATION_COUNT, {
    // variables: {
    //   region,
    // },
  });

  return {
    count: data?.countNotifications ?? 0,
    refetch,
    error,
    loading,
  };
}

export const GET_NOTIFICATIONS = gql`
  query getNotifications($from: Int) {
    notifications(from: $from) {
      id
      templateType
      body
      senderId
      receiverId
      readAt
      insertedAt
    }
  }
`;

export const GET_UNREAD_NOTIFICATION_COUNT = gql`
  query getUnreadNotificationCount {
    countNotifications(unreadOnly: true)
  }
`;

export enum UserNotificationTemplate {
  CLAN_ACCEPTED = 'CLAN_ACCEPTED',
  CLAN_REJECTED = 'CLAN_REJECTED',
  CLAN_JOIN_REQUEST = 'CLAN_JOIN_REQUEST',
  CLAN_LEAVE = 'CLAN_LEAVE',

  CLASH_INVITE = 'CLASH_INVITE',
  CLASH_ACCEPTED = 'CLASH_ACCEPTED',
  CLASH_REJECTED = 'CLASH_REJECTED',
  CLASH_JOIN_REQUEST = 'CLASH_JOIN_REQUEST',
  CLASH_LEAVE = 'CLASH_LEAVE',

  // 경고
  WARNING_AGGRO = 'WARNING_AGGRO',
  WARNING_ACCOUNT_TRANSACTION = 'WARNING_ACCOUNT_TRANSACTION',
  WARNING_BOOST = 'WARNING_BOOST',
  WARNING_INDUCING_CONFLICT = 'WARNING_INDUCING_CONFLICT',
  WARNING_AVERSION = 'WARNING_AVERSION',
  WARNING_PRIVATE = 'WARNING_PRIVATE',
  WARNING_ADVERTISE = 'WARNING_ADVERTISE',

  // 밴
  BAN_AGGRO = 'BAN_AGGRO',
  BAN_ACCOUNT_TRANSACTION = 'BAN_ACCOUNT_TRANSACTION',
  BAN_BOOST = 'BAN_BOOST',
  BAN_INDUCING_CONFLICT = 'BAN_INDUCING_CONFLICT',
  BAN_AVERSION = 'BAN_AVERSION',
  BAN_PRIVATE = 'BAN_PRIVATE',
  BAN_ADVERTISE = 'BAN_ADVERTISE',
}

export interface UserNotification {
  id: number;
  templateType: UserNotificationTemplate;
  senderId: string;
  receiverId: string;
  body?: string;
  readAt?: Date;
  insertedAt: Date;
  updatedAt: Date;
}

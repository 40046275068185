import React from 'react';
import classNames from 'classnames';

interface Props {
  size?: 'default' | 'small';
  className?: string;
}

const TwoKOIcon = ({ size = 'default', className }: Props) => (
  <img
    className={classNames(
      'block box-content',
      size === 'default' ? 'w-5 h-5 p-0.5' : 'w-4 h-4',
      className
    )}
    src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/2xko.svg"
  />
);

export default TwoKOIcon;

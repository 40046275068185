import { gql, useSubscription } from '@apollo/client';
import React from 'react';
import { CORE_NOTIFICATION } from '../fragments/notifications';
import { getToken } from '../utils/application';
import { FetchActionType } from '../types/ReducerTypes';
import { SteamPartner } from '../types/steam/partner.type';
import { UserNotification } from '../types/notification.type';
import { Nullable } from '../types/UtilTypes';

type NotificationsChangeSet = {
  action: FetchActionType;
  notification: UserNotification;
};

export const SUBSCRIPTION_NOTIFICATIONS = gql`
  ${CORE_NOTIFICATION}
  subscription NotificationUpdated($token: String!) {
    notificationUpdated(token: $token) {
      action
      notification {
        ...CoreNotification
      }
    }
  }
`;

export function useNotificationUpdated(): Nullable<NotificationsChangeSet> {
  const [changeSet, setChangeSet] =
    React.useState<Nullable<NotificationsChangeSet>>(null);
  const { data } = useSubscription<{
    notificationUpdated: NotificationsChangeSet;
  }>(SUBSCRIPTION_NOTIFICATIONS, {
    variables: {
      token: getToken() ?? '',
    },
    skip: !getToken(),
  });

  React.useEffect(() => {
    if (!data || !data.notificationUpdated) return;

    setChangeSet(data.notificationUpdated);
  }, [data]);

  return changeSet;
}

import classNames from 'classnames';
import React from 'react';
import { useConnections } from '../queries/connections';
import { formatNumber } from '../utils/misc';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const HeaderUserConnections: React.FC<Props> = ({ className }) => {
  const { connections } = useConnections();

  return (
    <strong
      className={classNames('w-10 text-right', className, {
        'text-white': connections < 50,
        'text-yellow-500': connections >= 50 && connections < 100,
        'text-red-500': connections >= 100,
      })}
    >
      {formatNumber(connections)}
    </strong>
  );
};

export default HeaderUserConnections;

import { BellIcon } from '@heroicons/react/24/outline';
import { Badge, IconButton, Popover } from '@mui/material';
import classNames from 'classnames';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import {
  useNotificationCount,
  useNotifications,
} from '../queries/notifications';
import NotificationCenter from './NotificationCenter';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const UserNotification: React.FC<Props> = ({ className }) => {
  const { count, refetch } = useNotificationCount();
  const { count: _count } = useNotifications();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'notificationPopover',
  });

  return (
    <div className={classNames('relative', className)}>
      <Badge
        badgeContent={_count ?? count}
        color="secondary"
        sx={{
          '& .MuiBadge-badge': {
            top: 2,
            right: 2,
          },
        }}
      >
        <IconButton
          {...bindTrigger(popupState)}
          onClick={(event) => {
            bindTrigger(popupState).onClick(event);
            dataLayer(
              {
                op_event: GTMEventInfoType.CLICK_NOTIFICATION,
              },
              GTMEventType.CLICK
            );
          }}
        >
          <BellIcon className="w-6 h-6" />
        </IconButton>
      </Badge>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationCenter onClose={handleClose} />
      </Popover>
    </div>
  );

  function handleClose() {
    refetch();
    popupState.close();
  }
};

export default UserNotification;

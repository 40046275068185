import React from 'react';
import { isClashSplitOne } from '../utils/date';
import { isProduction } from '../utils/misc';
import AdBox from './AdBox';
import AnnounceBar from './AnnounceBar';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';
import QwerBanner from './QwerBanner';
import RightSideBanner from './RightSideBanner';
import { useMember } from '../queries/member';
import ScrollToTopButton from './ScrollToTopButton';
import { SashBanner } from '@opgg-internal/opgg-kit';

type Props = {
  children: React.ReactNode;
  isConnecting: boolean;
};

const Layout: React.FC<Props> = ({ children, isConnecting }) => {
  const isClashPeriod = React.useMemo(() => isClashSplitOne(), []);
  const { user } = useMember();

  return (
    <div className="relative">
      <Header isConnecting={isConnecting} />
      <SashBanner />

      {isClashPeriod && <AnnounceBar />}
      <main id="duo-content" className="flex">
        {user?.adFree !== true && (
          <div id="duo-ad-vertical-left" className="flex-1 hidden xl:block">
            <div className="admanager--vertical admanager--vertical--left">
              <div className="admanager--box">
                {!isProduction() && <AdBox className="h-[600px]" />}
                <QwerBanner className="mt-2" position="left" disabled />
              </div>
            </div>
          </div>
        )}

        <Container className="my-4 flex-shrink-0 overflow-x-hidden overflow-y-hidden pb-36">
          {children}
        </Container>

        {user?.adFree !== true && (
          <div id="duo-ad-vertical-right" className="flex-1 hidden xl:block">
            <div className="admanager--vertical admanager--vertical--right">
              <div className="admanager--box">
                {!isProduction() && <AdBox className="h-[600px]" />}
                <RightSideBanner className="mt-2" disabled />
              </div>
            </div>
          </div>
        )}
      </main>

      <Footer />

      <ScrollToTopButton />
    </div>
  );
};

export default Layout;

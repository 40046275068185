import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { Nullable } from '../types/UtilTypes';
import { useMemberRedirect, useStateWithPartialSetter } from './utils';

type IUser = {
  id: number;
  mid: number;
  nick: string;
};

type IUserState = {
  userId: Nullable<string>;
  userMid: Nullable<string>;
  userToken: Nullable<string>;
  isSignedIn: boolean;
};

export function useUser(): {
  userId: Nullable<string>;
  userMid: Nullable<string>;
  userToken: Nullable<string>;
  isSignedIn: boolean;
  redirectToSignIn: () => void;
} {
  const [state, setState] = useStateWithPartialSetter<IUserState>({
    userId: null,
    userMid: null,
    userToken: null,
    isSignedIn: false,
  });
  const redirectToSignIn = useMemberRedirect();

  React.useEffect(() => {
    const usersToken = Cookies.get('_ot');

    if (!usersToken) {
      setState({
        userId: null,
        userMid: null,
        userToken: null,
        isSignedIn: false,
      });
      return;
    }

    try {
      const user = jwt_decode<IUser>(usersToken);

      setState({
        userId: user.id.toString(),
        userMid: user.mid.toString(),
        isSignedIn: true,
        userToken: usersToken,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return {
    ...state,
    redirectToSignIn,
  };
}

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export function isClashSplitOne() {
  const now = dayjs();

  return (
    now.isBetween('2023-03-20 00:00', dayjs('2023-03-26 24:00')) ||
    now.isBetween('2023-04-03 00:00', dayjs('2023-04-09 24:00')) ||
    now.isBetween('2023-04-17 00:00', dayjs('2023-04-23 24:00')) ||
    now.isBetween('2023-05-01 00:00', dayjs('2023-05-14 24:00')) ||
    now.isBetween('2023-05-29 00:00', dayjs('2023-06-04 24:00')) ||
    now.isBetween('2023-06-12 00:00', dayjs('2023-06-18 24:00'))
  );
}

import classNames from 'classnames';
import React from 'react';
import DiscordBanner from './DiscordBanner';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  disabled?: boolean;
};

const RightSideBanner: React.FC<Props> = ({ className, disabled }) => {
  return (
    <div
      className={classNames(className, {
        '-mb-2': !!disabled,
      })}
    >
      {!disabled && <DiscordBanner />}
    </div>
  );
};

export default RightSideBanner;

import { gql } from '@apollo/client';

export const CORE_SUMMONER_CHAMPION = gql`
  fragment CoreSummonerChampion on SummonerChampion {
    id
    cs
    games
    name
    kda
    kdaAvg
    wr
  }
`;

export const CORE_SUMMONER = gql`
  ${CORE_SUMMONER_CHAMPION}
  fragment CoreSummoner on Summoner {
    id
    name
    gameName
    tagLine
    tier
    kda
    kdaAverage
    wins
    losses
    region
    profileImageUrl
    previousTiers
    gameResults
    champions {
      ...CoreSummonerChampion
    }
  }
`;

export const CORE_PARTNER = gql`
  ${CORE_SUMMONER}
  fragment CorePartner on Partner {
    id
    position
    position2
    champion
    champion2
    requiredPosition
    note
    insertedAt
    queueType
    ipAddress
    options
    isAuthor
    isSubscription
    summoner {
      ...CoreSummoner
    }
  }
`;

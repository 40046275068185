import { useMemo } from 'react';
import dayjs from 'dayjs';
import BannedModal from './modal/common/BannedModal';
import { useRecoilState } from 'recoil';
import { notificationsState } from '../atoms/notification';

const BannedModalContainer = () => {
  const [notifications] = useRecoilState(notificationsState);
  const { bans } = notifications;
  const banNotification = useMemo(() => {
    if (bans.length === 0) return null;
    for (const noti of bans) {
      const date = dayjs(noti?.body).format('YYYY-MM-DD');
      const closedBannedModals = localStorage.getItem('closed-banned-modals');
      const closedBannedModalsToArray: string[] = closedBannedModals
        ? JSON.parse(closedBannedModals)
        : [];

      if (
        noti?.body &&
        dayjs(date).isAfter(dayjs()) &&
        !closedBannedModalsToArray.includes(noti.id.toString())
      ) {
        return noti;
      }
    }
    return null;
  }, [bans]);

  if (!banNotification) return null;

  return <BannedModal ban={banNotification} />;
};

export default BannedModalContainer;

import { GameResult } from '../PartnerTypes';

export interface ISummonerName {
  name: string;
  gameName?: string;
  tagLine?: string;
}

export interface ISummoner extends ISummonerName {
  id: string;
  profileImageUrl: string;
  champions: IChampion[];
  kda: string;
  kdaAverage: number;
  wins: number;
  losses: number;
  region: Region;
  tier: string;
  previousTiers: string[];
  gameResults: GameResult[];
}

export interface IChampion {
  id: string;
  name: string;
  cs: string;
  games: number;
  kda: string;
  kdaAvg: number;
  wr: string;
}

export interface CertifiedSummoner extends ISummonerName {
  profileImageUrl: string;
}

export enum Tier {
  ALL = 'ALL',
  UNRANKED = 'UNRANKED',
  IRON = 'IRON',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  EMERALD = 'EMERALD',
  DIAMOND = 'DIAMOND',
}

export enum Region {
  NA = 'na',
  EUW = 'euw',
  EUNE = 'eune',
  OCE = 'oce',
  ME = 'me',
  KR = 'kr',
  JP = 'jp',
  BR = 'br',
  LAS = 'las',
  LAN = 'lan',
  RU = 'ru',
  TR = 'tr',
  SG = 'sg',
  PH = 'ph',
  TW = 'tw',
  VN = 'vn',
  TH = 'th',
}

import { Region } from './lol/summoner.type';
import { OverwatchRegion } from './overwatch/player.type';
import { ValorantRegion } from './valorant/partner.type';
import TwoXKOIcon from '../components/TwoXKOIcon';

export enum SupportedGame {
  LeagueOfLegends = 'LOL',
  LeagueOfLegendsArena = 'ARENA',
  LeagueOfLegendsSwarm = 'SWARM',
  Valorant = 'VALORANT',
  Overwatch = 'OVERWATCH',
  TeamfightTactics = 'TFT',
  ARAM = 'ARAM',
  NexusBlitz = 'NEXUS_BLITZ',
  Palworld = 'PALWORLD',
  Steam = 'STEAM',
  TwoXKO = '2XKO',
}

export type DUOConfig = {
  [key in SupportedGame]?: {
    region: Region | ValorantRegion | OverwatchRegion;
    detected: boolean;
  };
};

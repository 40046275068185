import { Badge, Stack, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';
import React, { Fragment } from 'react';
import { useSelectedGame } from '../lib/games';
import { GameListType, GameProps } from './Header';
import { useRouter } from 'next/router';
import { Locale } from '../types/LocaleTypes';
import { SupportedGame } from '../types/game.type';
import { FormattedMessage } from 'react-intl';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  list: GameListType;
};

const GameSelect: React.FC<Props> = ({ className, list }) => {
  const selectedGame = useSelectedGame();
  const isXs = useMediaQuery('(max-width: 540px)');
  const { locale = Locale.KO } = useRouter();

  return (
    <div className={classNames('', className)}>
      <Stack
        component="nav"
        direction={{ sm: 'row', xs: 'row' }}
        alignItems="flex-start"
        justifyContent="flex-start"
        overflow={{ x: 'hidden' }}
        rowGap={1}
        columnGap={{ xs: 1, sm: 3 }}
        flexWrap={'wrap'}
      >
        {list.riot.map(
          ({ title, game, iconHref, icon, href, dot, event }, index) => {
            const item = (
              <Link
                href={href}
                className={classNames('flex items-center transition-opacity', {
                  'hover:opacity-80 opacity-40': selectedGame !== game,
                  'pl-[12px] xs:pl-[8px]': list.riot[index - 1]?.dot,
                })}
              >
                {icon ? (
                  icon
                ) : (
                  <img src={iconHref} width={24} height={24} alt={title} />
                )}

                <span className="xs:hidden sm:visible whitespace-nowrap">
                  <FormattedMessage id={title} />
                </span>
                {event && (
                  <span
                    className={
                      'px-1 py-0.5 border-[0.5px] border-yellow-500 text-yellow-500 text-[8px] rounded-[10px] ml-1 xs:hidden sm:visible'
                    }
                  >
                    Event
                  </span>
                )}
              </Link>
            );

            return (
              <div key={game}>
                {dot ? (
                  <Badge
                    key={game}
                    variant="dot"
                    color="error"
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 8,
                        right: !isXs ? -10 : -4,
                      },
                    }}
                  >
                    {item}
                  </Badge>
                ) : (
                  item
                )}
              </div>
            );
          }
        )}
      </Stack>
      <Stack
        marginTop={1}
        component="nav"
        direction={{ sm: 'row', xs: 'row' }}
        alignItems="flex-start"
        justifyContent="flex-start"
        overflow={{ x: 'hidden' }}
        rowGap={1}
        columnGap={{ xs: 1, sm: 3 }}
        flexWrap={'wrap'}
      >
        {list.default.map(
          ({ title, game, iconHref, icon, href, dot, event }, index) => {
            const item = (
              <Link
                href={href}
                className={classNames('flex items-center transition-opacity', {
                  'hover:opacity-80 opacity-40': selectedGame !== game,
                  'pl-[12px] xs:pl-[8px]': list.default[index - 1]?.dot,
                })}
              >
                {icon ? (
                  icon
                ) : (
                  <img src={iconHref} width={24} height={24} alt={title} />
                )}

                <span className="xs:hidden sm:visible whitespace-nowrap">
                  <FormattedMessage id={title} />
                </span>
                {event && (
                  <span
                    className={
                      'px-1 py-0.5 border-[0.5px] border-yellow-500 text-yellow-500 text-[8px] rounded-[10px] ml-1 xs:hidden sm:visible'
                    }
                  >
                    Event
                  </span>
                )}
              </Link>
            );

            return (
              <div key={game}>
                {dot ? (
                  <Badge
                    key={game}
                    variant="dot"
                    color="error"
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 8,
                        right: !isXs ? -10 : -4,
                      },
                    }}
                  >
                    {item}
                  </Badge>
                ) : (
                  item
                )}
              </div>
            );
          }
        )}
      </Stack>
    </div>
  );
};

export default GameSelect;

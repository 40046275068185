import { Indexable } from '../types/UtilTypes';
import en from './en.json';
import ja from './ja.json';
import ko from './ko.json';
import pt from './pt.json';
import th from './th.json';
import vi from './vi.json';
import zhTW from './zh-tw.json';

const locales: Indexable<{
  [key: string]: Record<string, string>;
}> = {
  default: en,
  ko,
  en,
  ja: {
    ...en,
    ...ja,
  },
  pt: {
    ...en,
    ...pt,
  },
  'zh-TW': {
    ...en,
    ...zhTW,
  },
  th: {
    ...en,
    ...th,
  },
  vi: {
    ...en,
    ...vi,
  },
};

export default locales;

import { IPartner } from '../PartnerTypes';
import { ISummoner } from './summoner.type';

export interface LoLPartner extends IPartner {
  position: Position;
  position2: Position;
  champion: string;
  champion2: string;
  requiredPosition: Position[];
  queueType: QueueType;
  summoner: ISummoner;
}

export enum Position {
  ALL = 'ALL',
  TOP = 'TOP',
  JUNGLE = 'JUNGLE',
  MID = 'MID',
  BOTTOM = 'BOTTOM',
  SUPPORT = 'SUPPORT',
}

export enum NexusPosition {
  ALL = 'ALL',
  JUNGLE = 'JUNGLE',
  BOTTOM = 'BOTTOM',
}

export enum QueueType {
  RANKED_SOLO = 'RANKED_SOLO',
  RANKED_FLEX = 'RANKED_FLEX',
  NORMAL = 'NORMAL',
  ARAM = 'ARAM',
  NEXUS_BLITZ = 'NEXUS_BLITZ',
}

export function isLoLPartner(partner: IPartner): partner is LoLPartner {
  return 'summoner' in partner;
}

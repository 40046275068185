import { Region } from '../lol/summoner.type';
import { GameResult } from '../PartnerTypes';

export interface ArenaSummoner {
  id: number;
  name: string;
  gameName?: string;
  tagLine?: string;
  profileImageUrl: string;
  tier: string;
  wins: number;
  losses: number;
  kda: string;
  kdaAverage: number;
  champions: ArenaSummonerChampion[];
  region: ArenaRegion;
  previousTiers: string[];
  gameResults: GameResult[];
  insertedAt: Date;
}

export interface ArenaSummonerChampion {
  id: string;
  name: string;
  wr: string;
  games: number;
  kda: string;
  kdaAvg: number;
}

export enum ArenaTier {
  ALL = 'ALL',
  UNRANKED = 'UNRANKED',
  WOOD = 'WOOD',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  GLADIATOR = 'GLADIATOR',
}

export enum ArenaRegion {
  NA = 'na',
  EUW = 'euw',
  EUNE = 'eune',
  OCE = 'oce',
  KR = 'kr',
  JP = 'jp',
  BR = 'br',
  LAS = 'las',
  LAN = 'lan',
  RU = 'ru',
  TR = 'tr',
  SG = 'sg',
  PH = 'ph',
  TW = 'tw',
  VN = 'vn',
  TH = 'th',
}

export const fromLOLRegiontoArena = (region: Region): ArenaRegion => {
  return (Object.entries(ArenaRegion).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', ArenaRegion.KR])[1];
};
export const fromArenaRegion = (region: ArenaRegion): Region => {
  return (Object.entries(Region).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', Region.KR])[1];
};

import React from 'react';
import { GlobalContext, GlobalState } from '../providers/GlobalStateProvider';
import {
  OverwatchContext,
  OverwatchState,
} from '../providers/OverwatchStateProvider';
import {
  ValorantContext,
  ValorantState,
} from '../providers/ValorantStateProvider';
import { TFTContext, TFTState } from '../providers/TFTStateProvider';
import { UseStateWithPartialSetter } from './utils';
import { ArenaContext, ArenaState } from '../providers/ArenaStateProvider';
import { AramContext, AramState } from '../providers/AramStateProvider';
import { NexusContext, NexusState } from '../providers/NexusStateProvider';
import { SwarmContext, SwarmState } from '../providers/SwarmStateProvider';

export function useGlobalState(): UseStateWithPartialSetter<GlobalState> {
  const context = React.useContext(GlobalContext);

  if (!context) {
    throw new Error(
      'Use <GlobalStateProvider>{children}</GlobalStateProvider> first'
    );
  }

  return context.state;
}

export function useNexusState(): UseStateWithPartialSetter<NexusState> {
  const context = React.useContext(NexusContext);

  if (!context) {
    throw new Error(
      'Use <NexusStateProvider>{children}</NexusStateProvider> first'
    );
  }

  return context.state;
}

export function useValorantState(): UseStateWithPartialSetter<ValorantState> {
  const context = React.useContext(ValorantContext);

  if (!context) {
    throw new Error(
      'Use <ValorantStateProvider>{children}</ValorantStateProvider> first'
    );
  }

  return context.state;
}

export function useOverwatchState(): UseStateWithPartialSetter<OverwatchState> {
  const context = React.useContext(OverwatchContext);

  if (!context) {
    throw new Error(
      'Use <OverwatchStateProvider>{children}</OverwatchStateProvider> first'
    );
  }

  return context.state;
}

export function useTFTState(): UseStateWithPartialSetter<TFTState> {
  const context = React.useContext(TFTContext);

  if (!context) {
    throw new Error(
      'Use <TFTStateProvider>{children}</TFTStateProvider> first'
    );
  }

  return context.state;
}

export function useArenaState(): UseStateWithPartialSetter<ArenaState> {
  const context = React.useContext(ArenaContext);

  if (!context) {
    throw new Error(
      'Use <ArenaStateProvider>{children}</ArenaStateProvider> first'
    );
  }

  return context.state;
}

export function useSwarmState(): UseStateWithPartialSetter<SwarmState> {
  const context = React.useContext(SwarmContext);

  if (!context) {
    throw new Error(
      'Use <SwarmStateProvider>{children}</SwarmStateProvider> first'
    );
  }

  return context.state;
}

export function useAramState(): UseStateWithPartialSetter<AramState> {
  const context = React.useContext(AramContext);

  if (!context) {
    throw new Error(
      'Use <AramStateProvider>{children}</AramStateProvider> first'
    );
  }

  return context.state;
}

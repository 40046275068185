export interface OverwatchPlayer {
  id: string;
  name: string;
  tagLine: string;
  tier: OverwatchTier;
  profileImageUrl?: string;
  tankTier: string;
  damageTier: string;
  supportTier: string;
  games: number;
  wins: number;
  losses: number;
  wr: number;
  kill: number;
  death: number;
  kd: string;
  kdAverage: number;
  insertedAt: string;
  heroes: [OverwatchHero];
}

export interface OverwatchHero {
  id: string;
  imageURL: string;
  name: string;
  wins: number;
  losses: number;
  kill: number;
  death: number;
  wr: string;
  games: number;
  kd: string;
  kdAvg: number;
  playTime: number;
  allDamageDone: number;
}

export enum OverwatchTier {
  All = 'ALL',
  Bronze = 'BRONZE',
  Silver = 'SILVER',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
  Diamond = 'DIAMOND',
  Master = 'MASTER',
}

export enum OverwatchRegion {
  Americas = 'americas',
  Europe = 'europe',
  Asia = 'asia',
}

import { useRouter } from 'next/router';
import { SupportedGame } from '../types/game.type';
import { GameResult } from '../types/PartnerTypes';
import { head, takeWhile } from 'lodash-es';

export const gamePaths = {
  [SupportedGame.LeagueOfLegends]: '/lol',
  [SupportedGame.LeagueOfLegendsArena]: '/lol-arena',
  [SupportedGame.LeagueOfLegendsSwarm]: '/lol-swarm',
  [SupportedGame.Valorant]: '/valorant',
  [SupportedGame.Overwatch]: '/overwatch',
  [SupportedGame.TeamfightTactics]: '/tft',
  [SupportedGame.ARAM]: '/aram',
  [SupportedGame.NexusBlitz]: '/nexus-blitz',
  [SupportedGame.Palworld]: '/palworld',
  [SupportedGame.Steam]: '/steam',
  [SupportedGame.TwoXKO]: '/2xko',
};

export function useSelectedGame(): SupportedGame {
  const router = useRouter();

  const selectedPath = Object.entries(gamePaths).find(
    ([, path]) => path === router.route
  );

  return selectedPath
    ? (selectedPath[0] as SupportedGame)
    : SupportedGame.LeagueOfLegends;
}

export function getStreakCount(results: GameResult[]): {
  type: GameResult;
  count: number;
} {
  const mapped = results
    .map((result) => (result === 'MVP' ? 'WIN' : result))
    .filter((result) => result !== 'DRAW');
  const first = head(mapped);
  const count = takeWhile(mapped, (result) => result === first).length;

  return {
    type: first ?? 'UNKNOWN',
    count,
  };
}

function getResultCount(results: GameResult[], filter: GameResult): number {
  return results.filter((result) => result === filter).length;
}

export function getMVPCount(results: GameResult[]): number {
  return getResultCount(results, 'MVP');
}

export function getACECount(results: GameResult[]): number {
  return getResultCount(results, 'ACE');
}

import { XMarkIcon } from '@heroicons/react/24/solid';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container from './Container';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const announceKey = 'announce-bar.clash';

const AnnounceBar: React.FC<Props> = ({ className }) => {
  const [visible, setVisible] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setVisible(false);
    localStorage.setItem(announceKey, new Date().toISOString());
  }, []);

  React.useEffect(() => {
    const found = Boolean(localStorage.getItem(announceKey));
    setVisible(!found);
  }, []);

  return (
    <Collapse in={visible} unmountOnExit>
      <Box className={classNames('bg-yellow-400 text-gray-900', className)}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography py={1.25}>
              <FormattedMessage id={announceKey} />
            </Typography>
            <IconButton color="inherit" onClick={handleClick}>
              <XMarkIcon width={20} height={20} />
            </IconButton>
          </Stack>
        </Container>
      </Box>
    </Collapse>
  );
};

export default AnnounceBar;

import React from 'react';
import {
  StateWithPartialSetter,
  useStateWithPartialSetter,
} from '../hooks/utils';
import stateWithStorage from '../utils/stateWithStorage';
import { Tier } from '../types/lol/summoner.type';

export type AramState = {
  tier: Tier;
  isAuth: boolean;
};

export const AramContext = React.createContext<{
  state: StateWithPartialSetter<AramState>;
} | null>(null);

interface Props {
  children: React.ReactNode;
}

export const AramStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useStateWithPartialSetter<AramState>({
    tier: Tier.ALL,
    isAuth: false,
  });
  stateWithStorage(
    'global-aram-state',
    state,
    setState,
    state,
    'sessionStorage'
  );
  return (
    <AramContext.Provider
      value={{
        state: [state, setState],
      }}
    >
      {children}
    </AramContext.Provider>
  );
};

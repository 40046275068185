import { Region } from '../lol/summoner.type';
import { GameResult } from '../PartnerTypes';

export interface SwarmSummoner {
  id: number;
  name: string;
  gameName: string;
  tagLine: string;
  profileImageUrl: string;
  region: SwarmRegion;
  insertedAt: Date;
}

export enum SwarmRegion {
  NA = 'na',
  EUW = 'euw',
  EUNE = 'eune',
  OCE = 'oce',
  ME = 'me',
  KR = 'kr',
  JP = 'jp',
  BR = 'br',
  LAS = 'las',
  LAN = 'lan',
  RU = 'ru',
  TR = 'tr',
  SG = 'sg',
  PH = 'ph',
  TW = 'tw',
  VN = 'vn',
  TH = 'th',
}

export const fromLOLRegiontoSwarm = (region: Region): SwarmRegion => {
  return (Object.entries(SwarmRegion).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', SwarmRegion.KR])[1];
};
export const fromSwarmRegion = (region: SwarmRegion): Region => {
  return (Object.entries(Region).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', Region.KR])[1];
};

import { Dialog, Transition } from '@headlessui/react';
import { Paper } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useCancel } from '../../hooks/modal';
import { noop } from '../../utils/misc';
import CloseButton from '../CloseButton';
import { motion } from 'framer-motion';

interface Props {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  children: React.ReactNode;
}

interface ModalProps extends Props {
  contentClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  onClose: () => void;
  useCancel?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  className,
  contentClassName,
  visible,
  onClose: handleClose,
  children,
  useCancel: cancel = true,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  if (cancel) {
    useCancel(ref, handleClose);
  }

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-[100000]" onClose={noop}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              className,
              'fixed inset-0 bg-gray-900 bg-opacity-80'
            )}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                ref={ref}
                className={classNames(contentClassName, 'w-full')}
              >
                <Paper elevation={3}>{children}</Paper>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface HeaderProps extends Props {
  onClose?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  className,
  children,
  onClose: handleClose,
}) => {
  return (
    <div
      className={classNames(
        className,
        'flex items-center border-b relative border-gray-900'
      )}
    >
      <div className="w-full flex items-center justify-between px-3 py-3">
        {children}
        {handleClose && <CloseButton className="ml-3" onClose={handleClose} />}
      </div>
    </div>
  );
};

interface MotionHeaderProps extends HeaderProps {
  animate?: boolean;
}

const MotionHeader = ({
  className,
  children,
  onClose,
  animate = false,
}: MotionHeaderProps) => {
  const headerComponent = (
    <Header className={className} onClose={onClose}>
      {children}
    </Header>
  );

  if (!animate) return headerComponent;

  return (
    <motion.div
      animate={{
        background: [
          'linear-gradient(90deg, rgba(126, 172, 255, 0) 0%, rgba(126, 172, 255, 0.00) 0%), #31313C',
          'linear-gradient(90deg, rgba(126, 172, 255, 1) 0%, rgba(126, 172, 255, 0.00) 70%), #31313C',
          'linear-gradient(90deg, rgba(126, 172, 255, 0) 0%, rgba(126, 172, 255, 0.00) 0%), #31313C',
          'linear-gradient(90deg, rgba(126, 172, 255, 0.3) 0%, rgba(126, 172, 255, 0.00) 70px), #31313C',
        ],
      }}
      transition={{
        duration: 2.5,
        ease: 'easeInOut',
        repeat: 0,
        times: [0, 0.25, 0.8, 1],
      }}
    >
      {headerComponent}
    </motion.div>
  );
};

export default Object.assign(Modal, { Header, MotionHeader });

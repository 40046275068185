import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { isProduction } from '../utils/misc';
import { useMember } from '../queries/member';

type Props = {
  children?: React.ReactNode;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const AdBox: React.FC<Props> = ({ children, className }) => {
  const { user } = useMember();
  if (user?.adFree) return null;
  return (
    <Box
      id="duo-ad-box"
      className={classNames(
        'bg-gray-850 flex justify-center items-center rounded py-2 sm:py-0 overflow-hidden',
        {
          'min-h-[160px] border border-gray-600 border-dashed text-xs text-gray-600':
            !isProduction(),
        },
        className
      )}
    >
      {children}
      {!isProduction() && 'AD'}
    </Box>
  );
};

export default AdBox;

import { IPartner } from '../PartnerTypes';
import { ValorantAgentClass } from './agent.type';
import { ValorantPlayer } from './player.type';

export interface ValorantPartner extends IPartner {
  player: ValorantPlayer;
  position: ValorantAgentClass;
  queueType: ValorantQueueType;
}

export enum ValorantRegion {
  KR = 'kr',
  NA = 'na',
  EU = 'eu',
  AP = 'ap',
  BR = 'br',
  LATAM = 'latam',
}

export enum ValorantQueueType {
  Competitive = 'COMPETITIVE',
  Custom = 'CUSTOM',
  Spike = 'SPIKE',
  Deathmatch = 'DEATHMATCH',
  Unrated = 'UNRATED',
  Escalation = 'ESCALATION',
  Onefa = 'ONEFA',
  TeamDeathmatch = 'TEAM_DEATHMATCH',
  QuickPlay = 'QUICKPLAY',
}

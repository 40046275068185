import { isEmpty, isNumber } from 'lodash-es';
import { Nullable } from '../types/UtilTypes';
import { OverwatchPlayer } from '../types/overwatch/player.type';
import { ValorantPlayer } from '../types/valorant/player.type';

export function isBrowser(): boolean {
  return typeof window === 'object';
}

export function isMobile(): boolean {
  return (
    isBrowser() && /Mobi|Android|FB_IAB|FBAN|FBAV/i.test(navigator.userAgent)
  );
}
export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}
export function graphqlWsUrl(): string {
  console.log(process.env.NEXT_PUBLIC_GRAPHQL_WS_URI);
  if (isProduction()) {
    return `wss://${location.host}/graphql`;
  } else if (process.env.NEXT_PUBLIC_GRAPHQL_WS_URI) {
    return process.env.NEXT_PUBLIC_GRAPHQL_WS_URI;
  } else {
    console.log('fallback localhost 4000', process.env);
    return `ws://${location.host}/graphql`;
  }
}
export function graphqlHttpUrl(): string {
  if (isProduction()) {
    return `/graphql`;
  } else if (process.env.NEXT_PUBLIC_GRAPHQL_HTTP_URI) {
    return process.env.NEXT_PUBLIC_GRAPHQL_HTTP_URI;
  } else {
    return '/graphql';
  }
}

export function resolveOpUrl(name: string, region: string): string {
  return `https://www.op.gg/summoners/${region}/${name}`;
}

export function resolveOpTagUrl(
  gameName: string,
  tagLine: string,
  region: string
): string {
  return `https://www.op.gg/summoners/${region}/${gameName}-${tagLine}`;
}

export function resolveTFTOpUrl(name: string, region: string): string {
  return `https://tft.op.gg/summoners/${region}/${name}`;
}

export function resolveTFTOpTagUrl(
  gameName: string,
  tagLine: string,
  region: string
): string {
  return `https://tft.op.gg/summoners/${region}/${gameName}-${tagLine}`;
}

export function resolveValorantOpUrl(player: ValorantPlayer): string {
  return `https://valorant.op.gg/profile/${player.name}-${player.tagLine}`;
}

export function resolveOverwatchOpUrl(player: OverwatchPlayer): string {
  return `https://overwatch.op.gg/search/?playerName=${player.name}#${player.tagLine}`;
}

export function formatNumber(value?: number, fallbackString = '...'): string {
  if (!isNumber(value)) {
    return fallbackString;
  }

  return new Intl.NumberFormat().format(value);
}

export function validateURL(url?: string | null): boolean {
  if (!url) return false;

  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(): void {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function asyncNoop(): Promise<void> {}

export function trimming(str: Nullable<string>): Nullable<string> {
  if (!str || Object.prototype.toString.call(str) !== '[object String]')
    return null;

  const trimmed = str.trim();
  if (isEmpty(trimmed)) return null;
  return trimmed;
}

import React from 'react';
import {
  StateWithPartialSetter,
  useStateWithPartialSetter,
} from '../hooks/utils';
import { Position, QueueType } from '../types/lol/partner.type';
import { Tier } from '../types/lol/summoner.type';
import { Nullable } from '../types/UtilTypes';
import stateWithStorage from '../utils/stateWithStorage';
import { MemberV2 } from '../types/MemberTypes';

export type GlobalState = {
  queueType: QueueType;
  position: Nullable<Position>;
  tier: Tier;
  isAuth: boolean;
  user: MemberV2 | null;
};

export const GlobalContext = React.createContext<{
  state: StateWithPartialSetter<GlobalState>;
} | null>(null);

interface Props {
  children: React.ReactNode;
}

export const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useStateWithPartialSetter<GlobalState>({
    queueType: QueueType.RANKED_SOLO,
    position: Position.ALL,
    tier: Tier.ALL,
    isAuth: false,
    user: null,
  });
  stateWithStorage('global-state', state, setState, state, 'sessionStorage');
  return (
    <GlobalContext.Provider
      value={{
        state: [state, setState],
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

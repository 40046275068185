import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      id="duo-container"
      className={classNames(
        'relative max-w-screen-lg mx-auto px-2.5 lg:px-0 w-full lg:w-container',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;

import { Badge, Paper } from '@mui/material';
import classNames from 'classnames';
import { isNumber } from 'lodash-es';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useWindowScroll } from 'react-use';
import { useUser } from '../hooks/user';
import { gamePaths, useSelectedGame } from '../lib/games';
import { useCountPartner } from '../queries/partners';
import { SupportedGame } from '../types/game.type';
import Container from './Container';
import GameSelect from './GameSelect';
import HeaderUserConnections from './HeaderUserConnections';
import Spinner from './Spinner';
import UserNotification from './UserNotification';
import SteamIcon from './SteamIcon';
import TwoXKOIcon from './TwoXKOIcon';
import { useNotificationUpdated } from '../subscriptions/notifications';

interface RouteProps {
  label: string;
  path: string;
  showBadge?: boolean;
  signInRequired?: boolean;
}

const LOL_ROUTES: RouteProps[] = [
  { label: 'Find DUO', path: '/lol' },
  { label: 'Trollbox', path: '/lol/trollbox' },
  { label: 'Find Clan', path: '/lol/clans' },
  { label: 'Find Clash Team', path: '/lol/clash', showBadge: false },
  { label: 'My Page', path: '/lol/me', signInRequired: true },
];

const ROUTE_MAP: { [key: string]: RouteProps[] } = {
  [SupportedGame.LeagueOfLegends]: LOL_ROUTES,
  [SupportedGame.Valorant]: [{ label: 'Find DUO', path: '/valorant' }],
  [SupportedGame.Overwatch]: [{ label: 'Find DUO', path: '/overwatch' }],
  [SupportedGame.TeamfightTactics]: [{ label: 'Find DUO', path: '/tft' }],
  [SupportedGame.ARAM]: [{ label: 'Find DUO', path: '/aram' }],
  [SupportedGame.NexusBlitz]: [{ label: 'Find DUO', path: '/nexus-blitz' }],
  [SupportedGame.LeagueOfLegendsArena]: [
    { label: 'Find DUO', path: '/lol-arena' },
  ],
  [SupportedGame.LeagueOfLegendsSwarm]: [
    { label: 'Find DUO', path: '/lol-swarm' },
  ],
  [SupportedGame.Palworld]: [{ label: 'Find Worlds', path: '/palworld' }],
  [SupportedGame.Steam]: [{ label: 'Find Players', path: '/steam' }],
  [SupportedGame.TwoXKO]: [{ label: 'Find Players', path: '/2xko' }],
};

export interface GameProps {
  game: SupportedGame;
  href: string;
  title: string;
  iconHref?: string;
  icon?: React.ReactNode;
  dot?: boolean;
  event?: boolean;
}

export interface GameListType {
  riot: GameProps[];
  default: GameProps[];
}

const GAME_LIST: GameListType = {
  riot: [
    {
      game: SupportedGame.LeagueOfLegends,
      href: gamePaths[SupportedGame.LeagueOfLegends],
      title: 'League of Legends',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-lol.png',
    },
    {
      game: SupportedGame.LeagueOfLegendsSwarm,
      href: gamePaths[SupportedGame.LeagueOfLegendsSwarm],
      title: 'LOL_SWARM',
      iconHref: '//duo-static.op.gg/info/swarm/icon/icon_swarm.svg',
      dot: true,
    },
    {
      game: SupportedGame.ARAM,
      href: gamePaths[SupportedGame.ARAM],
      title: 'ARAM',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-aram.svg',
    },
    {
      game: SupportedGame.TeamfightTactics,
      href: gamePaths[SupportedGame.TeamfightTactics],
      title: 'TFT',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-tft.svg',
      dot: true,
    },
    {
      game: SupportedGame.Valorant,
      href: gamePaths[SupportedGame.Valorant],
      title: 'Valorant',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-valorant.png',
    },
    {
      game: SupportedGame.LeagueOfLegendsArena,
      href: gamePaths[SupportedGame.LeagueOfLegendsArena],
      title: 'ARENA',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/arena.svg',
    },
  ],
  default: [
    {
      game: SupportedGame.Steam,
      href: gamePaths[SupportedGame.Steam],
      title: 'Steam',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/steam.svg',
      icon: <SteamIcon />,
    },
    {
      game: SupportedGame.Palworld,
      href: gamePaths[SupportedGame.Palworld],
      title: 'Palworld',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-palworld.svg',
    },
    {
      game: SupportedGame.Overwatch,
      href: gamePaths[SupportedGame.Overwatch],
      title: 'Overwatch2',
      iconHref:
        '//opgg-desktop-data.akamaized.net/download/duo/images/icons/game-icon-overwatch.png',
    },
    {
      game: SupportedGame.TwoXKO,
      href: gamePaths[SupportedGame.TwoXKO],
      title: '2XKO',
      icon: <TwoXKOIcon />,
    },
  ],
};

interface Props {
  className?: string;
  isConnecting: boolean;
}

const Header: React.FC<Props> = ({ className, isConnecting }) => {
  const { count } = useCountPartner();
  const { route: path } = useRouter();
  const { isSignedIn } = useUser();
  const { y: positionY } = useWindowScroll();
  const selectedGame = useSelectedGame();
  const [visiblePolicyPopup, setVisiblePolicyPopup] = useState(false);

  return (
    <header
      id="duo-header"
      className={classNames('sticky top-0 text-sm z-[9]', className)}
    >
      <Paper
        elevation={positionY > 0 ? 3 : 0}
        square
        sx={{ bgcolor: '#282830' }}
      >
        <Container className="flex items-center justify-between m-auto py-3.5">
          <div className="flex lg:flex-row lg:items-center sm:flex-col sm:items-start">
            <h1 className="font-gilroy font-bold text-gray-100 select-none lg:text-4xl sm:text-3xl xs:hidden">
              <Link href="/">
                <img src="/duo.svg" alt={'DUO'} />
              </Link>
            </h1>

            <GameSelect
              className="hidden xs:inline lg:inline lg:mx-8"
              list={GAME_LIST}
            />

            {!isConnecting && <Spinner className="ml-3 mt-0.5 sm:hidden" />}
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-2xs capitalize text-gray-300">
              <div className="flex">
                <div className="flex-1 flex items-center">
                  <img
                    src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/connection.svg"
                    alt="connection icon"
                    className="mr-1 w-4 h-4"
                  />
                  <FormattedMessage id="users online" />
                </div>
                <HeaderUserConnections />
              </div>
              <div className="flex whitespace-nowrap">
                <div className="flex-1 flex items-center">
                  <img
                    src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/user.svg"
                    alt="user icon"
                    className="mr-1 w-4 h-4"
                  />
                  <FormattedMessage id="Today's DUO Posts" />
                </div>
                <strong
                  className={classNames('w-10 text-right text-white', {
                    // TODO: define visualization
                    // 'text-yellow-500': count > 50,
                    // 'text-red-500': count > 100,
                  })}
                >
                  {formatNumber(count)}
                </strong>
              </div>
            </div>
          </div>
        </Container>

        <GameSelect
          className="xs:hidden lg:hidden px-2 pb-3"
          list={GAME_LIST}
        />

        <section className="border-t border-gray-900">
          <Container className="flex items-center justify-between !px-3 xs:!pr-0">
            <ul className="flex space-x-4 whitespace-nowrap overflow-auto mr-4">
              {(ROUTE_MAP[selectedGame] ?? [])
                .filter((route) => {
                  if (!route.signInRequired) return true;
                  return isSignedIn;
                })
                .map((route) => {
                  const selected =
                    route.path === '/lol'
                      ? path === route.path
                      : path.indexOf(route.path) !== -1;

                  return (
                    <li
                      key={route.label}
                      className={classNames('text-sm', {
                        'border-b-3 border-white': selected,
                      })}
                    >
                      {route.showBadge ? (
                        <Badge
                          variant="dot"
                          color="primary"
                          sx={{
                            '& .MuiBadge-badge': {
                              top: 14,
                              right: -6,
                            },
                          }}
                        >
                          <Link
                            href={route.path}
                            className={classNames(
                              'py-3 inline-block',
                              selected
                                ? 'text-white font-bold'
                                : 'text-gray-200'
                            )}
                          >
                            {selectedGame === SupportedGame.ARAM ? (
                              '9월 20일, Peyz 선수와 칼바람을 플레이 해보세요'
                            ) : (
                              <FormattedMessage id={route.label} />
                            )}
                          </Link>
                        </Badge>
                      ) : (
                        <Link
                          href={route.path}
                          className={classNames(
                            'py-3 inline-block',
                            selected ? 'text-white font-bold' : 'text-gray-200'
                          )}
                        >
                          <FormattedMessage id={route.label} />
                        </Link>
                      )}
                    </li>
                  );
                })}
            </ul>
            <section className="flex justify-end items-center gap-2 sm:space-x-0 flex-1">
              {selectedGame === SupportedGame.Palworld && (
                <ul className="flex gap-1 xs:hidden">
                  <li>
                    <a
                      className="flex items-center gap-1 bg-gray-700 py-1.5 px-2.5 rounded-md"
                      href="https://pal.op.gg/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-6 h-6"
                        src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/windows.png"
                      />
                      <div>Palmap</div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="flex items-center gap-1 bg-gray-700 py-1.5 px-2.5 rounded-md"
                      href="https://store.steampowered.com/app/1623730/Palworld/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SteamIcon />
                      <div>Palworld</div>
                    </a>
                  </li>
                </ul>
              )}
              {selectedGame === SupportedGame.Steam && (
                <ul className="flex gap-1 xs:hidden">
                  <li>
                    <a
                      className="flex items-center gap-1 bg-gray-700 py-1.5 px-2.5 rounded-md"
                      href="https://store.steampowered.com/search"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SteamIcon />
                      <div>
                        <FormattedMessage id="Steam" />
                      </div>
                    </a>
                  </li>
                </ul>
              )}
              <div className="flex items-center h-fit-content rounded-full bg-gray-700 py-1.5 px-3 sm:hidden relative">
                <img
                  className="w-5.5 h-5.5"
                  src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/info-24.svg"
                />
                <span className="text-2xs text-white ml-1.5 break-keep">
                  <FormattedMessage
                    id="Reckless theft or impersonation may result in penalties!"
                    values={{
                      b: (chunks) => <b className="text-red-500">{chunks}</b>,
                      br: <br />,
                    }}
                  />
                </span>
                <span
                  className="text-white text-2xs ml-[8px] font-bold underline cursor-pointer whitespace-nowrap"
                  onClick={() => setVisiblePolicyPopup(true)}
                >
                  <FormattedMessage id="Learn more" />
                </span>
                {visiblePolicyPopup && (
                  <>
                    <div className="absolute rounded-[4px] top-[calc(100%+5px)] left-0 bg-[#31313B] w-[400px] px-[20px] py-[24px] box-border z-[100001]">
                      <div className="flex justify-between items-center">
                        <div className="font-bold text-sm">
                          <FormattedMessage id="policy.use-compliance" />
                        </div>
                        <div
                          className="w-[24px] h-[24px] flex justify-center items-center cursor-pointer"
                          onClick={() => setVisiblePolicyPopup(false)}
                        >
                          <img src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/close.svg" />
                        </div>
                      </div>
                      <div className="mt-[8px] text-gray-200 text-3xs">
                        <FormattedMessage
                          id="policy.use-compliance-description"
                          values={{ br: <br /> }}
                        />
                      </div>
                      <div className="mt-[24px] font-bold text-sm">
                        <FormattedMessage id="policy.basic-etiquette" />
                      </div>
                      <div className="mt-[8px] text-gray-200 text-3xs">
                        <FormattedMessage
                          id="policy.basic-etiquette-description"
                          values={{ br: <br /> }}
                        />
                      </div>
                      <div className="mt-[20px] text-3xs text-gray-100">
                        <FormattedMessage
                          id="policy.basic-etiquette-content"
                          values={{ br: <br /> }}
                        />
                      </div>
                      <div className="mt-[24px] font-bold text-sm">
                        <FormattedMessage id="policy.member-inquiries" />
                      </div>
                      <div className="mt-[8px] text-gray-200 text-3xs">
                        <FormattedMessage
                          id="policy.member-inquiries-description"
                          values={{ br: <br /> }}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => setVisiblePolicyPopup(false)}
                      className="fixed top-0 left-0 w-screen h-screen bg-black opacity-50 z-[100000]"
                    ></div>
                  </>
                )}
              </div>
              <a
                className="xs:hidden"
                href="https://discord.gg/r5ASfM2fuf"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/discord.svg"
                  width={24}
                  height={24}
                  alt="Discord"
                />
              </a>
              {isSignedIn && <UserNotification />}
            </section>
          </Container>
        </section>
      </Paper>
    </header>
  );
};

export default Header;

function formatNumber(value?: number) {
  if (!isNumber(value)) {
    return '...';
  }

  return new Intl.NumberFormat().format(value);
}

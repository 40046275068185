import { GameResult } from '../PartnerTypes';
import { ValorantAgent } from './agent.type';
import { ValorantWeaponStats } from './weapon.type';

export enum ValorantTier {
  All = 'ALL',
  Iron = 'IRON',
  Bronze = 'BRONZE',
  Silver = 'SILVER',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
  Diamond = 'DIAMOND',
  Ascendant = 'ASCENDANT',
  Immortal = 'IMMORTAL',
  Radiant = 'RADIANT',
}

export interface ValorantPlayer {
  id: string;
  name: string;
  tagLine: string;
  tier: ValorantTier;
  wins: number;
  losses: number;
  kda: string;
  kdaAverage: number;
  agents: ValorantAgent[];
  previousTiers: string[];
  gameResults: GameResult[];
  insertedAt: string;
  weapons: ValorantWeaponStats[];
}

export interface CertifiedValorantPlayer {
  name: string;
  tagLine: string;
  region: string;
  policy: string;
}

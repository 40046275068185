import Cookies from 'js-cookie';
import {
  ExternalUrlObject,
  ExternalUrlType,
  IExternalUrlInfo,
} from '../types/ClanTypes';
import { Nullable } from '../types/UtilTypes';
import { DUOConfig, SupportedGame } from '../types/game.type';
import { Region } from '../types/lol/summoner.type';
import { OverwatchRegion } from '../types/overwatch/player.type';
import {
  TFTRegion,
  fromLOLRegion,
  fromTFTRegion,
} from '../types/tft/summoner.type';
import { ValorantRegion } from '../types/valorant/partner.type';
import {
  ArenaRegion,
  fromArenaRegion,
  fromLOLRegiontoArena,
} from '../types/arena/summoner.type';
import {
  SwarmRegion,
  fromLOLRegiontoSwarm,
  fromSwarmRegion,
} from '../types/swarm/summoner.type';

export const RegionConfigKey = '_rc';

export function getToken() {
  return Cookies.get('_ot');
}

export function getRegion(): Region {
  return (Cookies.get('NEXT_REGION') as Region) || Region.KR;
}

export function setRegion(region: Region) {
  Cookies.set('NEXT_REGION', region, {
    expires: 365,
    secure: true,
  });
}

export function getArenaRegion(): ArenaRegion {
  return fromLOLRegiontoArena(getRegion());
}

export function setArenaRegion(region: ArenaRegion) {
  setRegion(fromArenaRegion(region));
}

export function getSwarmRegion(): SwarmRegion {
  return fromLOLRegiontoSwarm(getRegion());
}

export function setSwarmRegion(region: SwarmRegion) {
  setRegion(fromSwarmRegion(region));
}

export function getTFTRegion(): TFTRegion {
  return fromLOLRegion(getRegion());
}

export function setTFTRegion(region: TFTRegion) {
  setRegion(fromTFTRegion(region));
}

export function getValorantRegion(): ValorantRegion {
  return (
    (Cookies.get('NEXT_REGION_VAL') as ValorantRegion) || ValorantRegion.KR
  );
}

export function setValorantRegion(region: ValorantRegion) {
  Cookies.set('NEXT_REGION_VAL', region, {
    expires: 365,
    secure: true,
  });
}

export function getOverwatchRegion(): OverwatchRegion {
  return (
    (Cookies.get('NEXT_REGION_OW') as OverwatchRegion) || OverwatchRegion.Asia
  );
}

export function setOverwatchRegion(region: OverwatchRegion) {
  Cookies.set('NEXT_REGION_OW', region, {
    expires: 365,
    secure: true,
  });
}

export function generateRandomColorByText(text: string): string {
  const seed = Math.abs(
    Math.sin(
      Number(
        text
          .split('')
          .map((x) => x.charCodeAt(0))
          .join('')
      )
    )
  );
  const hue = Math.floor(360 * seed); // 0-360
  const saturation = Math.floor(25 + 70 * seed); // 25-95%
  const lightness = Math.floor(60 + 10 * seed); // 60-70%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function getExternalUrls(
  urls: Nullable<IExternalUrlInfo[]>
): ExternalUrlObject {
  const defaultValue = { discordUrl: null, kakaoUrl: null };

  if (!urls) return defaultValue;

  return urls.reduce<ExternalUrlObject>((prev, next) => {
    if (next.type === ExternalUrlType.DISCORD) {
      prev.discordUrl = next.url;
    }

    if (next.type === ExternalUrlType.KAKAOTALK) {
      prev.kakaoUrl = next.url;
    }

    return prev;
  }, defaultValue);
}

export function setRegionConfig({
  game,
  region,
  detected = false,
  prevConfig,
}: {
  game: SupportedGame;
  region: Region | ValorantRegion | OverwatchRegion;
  detected?: boolean;
  prevConfig?: DUOConfig | null;
}): DUOConfig | null {
  try {
    const config: DUOConfig = {
      ...(prevConfig ?? {}),
      [game]: { region, detected },
    };

    Cookies.set(RegionConfigKey, JSON.stringify(config), {
      expires: 365,
      secure: true,
    });
    return config;
  } catch (error) {
    return null;
  }
}

export function getRegionConfig(): DUOConfig | null {
  const cookie = Cookies.get(RegionConfigKey);
  if (!cookie) return null;

  try {
    return JSON.parse(cookie) as DUOConfig;
  } catch (error) {
    return null;
  }
}

import { ApolloError, gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { last } from 'lodash-es';
import React, { useState } from 'react';
import { CORE_PARTNER } from '../fragments/partners';
import { usePartnerUpdated } from '../subscriptions/partners';
import { LoLPartner, Position, QueueType } from '../types/lol/partner.type';
import { Region, Tier } from '../types/lol/summoner.type';
import { FetchActionType } from '../types/ReducerTypes';
import { Nullable } from '../types/UtilTypes';

export const PAGE_LIMIT = 50;

export const GET_PARTNERS = gql`
  ${CORE_PARTNER}
  query partners(
    $tier: String
    $region: String
    $position: String
    $queueType: String
    $isAuth: Boolean
    $from: Int
  ) {
    partners(
      tier: $tier
      region: $region
      position: $position
      isAuth: $isAuth
      queueType: $queueType
      from: $from
    ) {
      ...CorePartner
    }
  }
`;

export const COUNT_PARTNERS = gql`
  query CountPartners {
    countPartners: countAllPartners
  }
`;

export type LoLPartnerQueryVariables = {
  region: Region;
  position: Nullable<Position>;
  queueType: QueueType;
  tier: Tier;
  isAuth?: boolean;
  from?: number;
};

export function usePartners(variables: LoLPartnerQueryVariables): {
  partners: LoLPartner[];
  newPartner: LoLPartner | null;
  error?: ApolloError;
  loading: boolean;
  hasMore: boolean;
  fetchMore: () => Promise<void>;
} {
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);

  const {
    data,
    error,
    loading,
    refetch,
    fetchMore: _fetchMore,
  } = useQuery<{
    partners: LoLPartner[];
  }>(GET_PARTNERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      if (retryAttempt) return;

      setRetryAttempt(true);
      refetch();
    },
  });

  const [partners, setPartners] = React.useState<LoLPartner[]>([]);
  const [newPartner, setNewPartner] = React.useState<LoLPartner | null>(null);
  const [hasMore, setHasMore] = React.useState(false);
  const changeSet = usePartnerUpdated(variables);

  React.useEffect(() => {
    if (!data || partners.length > 0) return;

    setPartners(data.partners);
    setHasMore(data.partners.length === PAGE_LIMIT);
  }, [data]);

  React.useEffect(() => {
    return () => setPartners([]);
  }, [
    variables.position,
    variables.queueType,
    variables.region,
    variables.tier,
    variables.isAuth,
  ]);

  React.useEffect(() => {
    if (!changeSet) return;

    switch (changeSet.action) {
      case FetchActionType.ADDED:
        setPartners((prevEntries) => [changeSet.partner, ...prevEntries]);
        setNewPartner(changeSet.partner);
        return;

      case FetchActionType.UPDATED: {
        setPartners((prevEntries) => {
          const updatedIndex = prevEntries.findIndex(
            (partner) => partner.id === changeSet.partner.id
          );
          const entries = JSON.parse(JSON.stringify(prevEntries));
          entries[updatedIndex] = changeSet.partner;
          return entries;
        });
        return;
      }

      case FetchActionType.DELETED:
        setPartners((prevEntries) =>
          prevEntries.filter((p) => p.id !== changeSet.partner.id)
        );
        return;

      default:
        return;
    }
  }, [changeSet]);

  return {
    partners,
    newPartner,
    error,
    loading,
    hasMore,
    fetchMore,
  };

  async function fetchMore(): Promise<void> {
    const lastItem = last(partners);

    if (!lastItem) return;

    const { data } = await _fetchMore({
      variables: {
        from: dayjs(lastItem.insertedAt).unix(),
      },
    });

    setPartners((prev) => prev.concat(data.partners));
    setHasMore(data.partners.length === PAGE_LIMIT);
  }
}

export function useCountPartner(): { count: number } {
  const { data } = useQuery<{ countPartners: number }>(COUNT_PARTNERS, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    count: data?.countPartners ?? 0,
  };
}

import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { isProduction } from '../utils/misc';
import { useMember } from '../queries/member';
import { useZendeskJWT } from '../hooks/zendesk';

const AdScript = (
  <Script
    id="h.bidrich"
    src="https://gougoi.contentcave.co.kr/bidrich.js"
    data-inv="61e781d08e9d6778330717"
    data-media="opgg"
  ></Script>
);

const DynamicScripts: React.FC = () => {
  const { getMember, user } = useMember();
  const { jwt } = useZendeskJWT();
  const [adComponent, setAdComponent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (!isProduction()) return;
    getMember().then((r) => {
      if (r.data?.meV2?.user?.adFree) {
        return;
      }
      setAdComponent(AdScript);
    });
  }, []);

  useEffect(() => {
    if (jwt || user) {
      setupZendesk();
    }
  }, [jwt, user]);

  return (
    <>
      <Script
        id="ze-snippet"
        async
        strategy="afterInteractive"
        src="https://static.zdassets.com/ekr/snippet.js?key=8b7154d5-b1a4-4326-85bf-e549cb4b2be5"
        onLoad={setupZendesk}
      ></Script>
      {adComponent}
    </>
  );

  function setupZendesk() {
    const zendesk = window?.zE;
    if (!zendesk) return;

    zendesk('messenger', 'show');
    zendesk('messenger:set', 'conversationFields', [
      { id: '22636565424665', value: 'duo' },
    ]);
    if (!jwt) return;

    zendesk('messenger', 'loginUser', (cb: any) => cb(jwt));
    const conversationTags = [];
    if (!!user?.adFree) {
      conversationTags.push('subscriber');
    }
    zendesk('messenger:set', 'conversationTags', conversationTags);
  }
};
export default DynamicScripts;

import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  onClose: () => void;
};

const CloseButton: React.FC<Props> = ({ className, onClose: handleClose }) => {
  return (
    <button
      type="button"
      className="bg-gray-800 p-0 cursor-pointer focus:outline-none hover:bg-gray-800"
    >
      <img
        src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/cross.svg"
        className={classNames(className, 'w-6 h-6')}
        onClick={handleClose}
      />
    </button>
  );
};

export default CloseButton;

import React, { useEffect } from 'react';

function getStorage(storage: 'sessionStorage' | 'localStorage') {
  switch (storage) {
    case 'sessionStorage':
      return sessionStorage;
    case 'localStorage':
      return localStorage;
    default:
      throw new Error('Invalid storage type');
  }
}

function stateWithStorage<T>(
  key: string,
  state: T,
  setState: (state: T) => void,
  initialState: T,
  storageType: 'sessionStorage' | 'localStorage' = 'sessionStorage'
): void {
  const [isMounted, setIsMounted] = React.useState(false);
  useEffect(() => {
    const storage = getStorage(storageType);
    const objStr = storage.getItem(key);
    if (objStr) {
      const obj = JSON.parse(objStr);
      setState(Object.assign({}, initialState, obj, { isAuth: false }));
    }
    setIsMounted(true);
  }, []);
  useEffect(() => {
    if (isMounted) {
      const obj = Object.assign({}, state);
      const storage = getStorage(storageType);
      storage.setItem(key, JSON.stringify(obj));
    }
  }, [state]);
}

export default stateWithStorage;

import Modal from '../Modal';
import { UserNotification } from '../../../types/notification.type';
import { useModal } from '../../../hooks/modal';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { XCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { getSubstringAfterUnderscore } from '../../../utils/string';
import { useLocalStorage } from 'react-use';

type Props = {
  className?: string;
  ban: UserNotification;
};

const BannedModal = ({ className, ban }: Props) => {
  const intl = useIntl();
  const { visible, open: openModal, close: closeModal } = useModal();
  const [value, setValue] = useLocalStorage(
    'closed-banned-modals',
    JSON.stringify([])
  );

  useEffect(() => {
    openModal();
  }, []);

  return (
    <Modal
      className={className}
      onClose={closeModal}
      visible={visible}
      contentClassName={'w-auto max-w-[400px]'}
      useCancel={false}
    >
      <div className={'px-5 py-6 text-sm font-normal'}>
        <div className={'flex flex-col items-center '}>
          <div
            className={
              'w-full text-center pb-2 border-b-[1px] border-solid border-gray-900 text-base font-bold'
            }
          >
            <FormattedMessage id={'Notice of suspension of use'} />
          </div>
          <XCircleIcon className={'w-10 h-10 mt-3 mb-2 text-red-500'} />
          <div>
            <FormattedMessage
              id={'You have been suspended until {date} due to {reason}.'}
              values={{
                date: dayjs(ban.body).format('YYYY-MM-DD'),
                reason: intl.formatMessage({
                  id: getSubstringAfterUnderscore(ban.templateType),
                }),
              }}
            />
          </div>
          <button
            className={
              'mt-6 w-full bg-red-500 hover:bg-red-600 text-white cursor-pointer rounded text-bold py-2 px-4'
            }
            onClick={() => {
              setValue(
                JSON.stringify([...(value ? JSON.parse(value) : []), ban.id])
              );
              closeModal();
            }}
          >
            <FormattedMessage id="Confirm" />
          </button>
        </div>
        <div className={'text-gray-200 text-3xs mt-4'}>
          <FormattedMessage
            id={
              'Please contact us about account suspensions using the inquiry button at the bottom right.'
            }
          />
        </div>
        <div className={'text-gray-200 text-3xs'}>
          <FormattedMessage
            id={
              'You can be suspended regardless of the number of accumulated warnings.'
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default BannedModal;

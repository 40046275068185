import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useSubscriptionConnects } from '../subscriptions/connections';

export const GET_CONNECTIONS = gql`
  query {
    connections
  }
`;

export function useConnections(): {
  connections: number;
} {
  const [connections, setConnections] = React.useState(0);

  useSubscriptionConnects(setConnections);

  const { data, error } = useQuery<{ connections: number }>(GET_CONNECTIONS);

  if (error) {
    console.error('[Error] query Connections :', error);
  }

  React.useEffect(() => {
    if (!data?.connections) return;
    setConnections(data.connections);
  }, [data]);

  return {
    connections,
  };
}

import React from 'react';
import {
  StateWithPartialSetter,
  useStateWithPartialSetter,
} from '../hooks/utils';
import {
  OverwatchPosition,
  OverwatchQueueType,
} from '../types/overwatch/partner.type';
import { OverwatchTier } from '../types/overwatch/player.type';
import stateWithStorage from '../utils/stateWithStorage';

export type OverwatchState = {
  tier: OverwatchTier;
  position: OverwatchPosition;
  queueType: OverwatchQueueType;
};

export const OverwatchContext = React.createContext<{
  state: StateWithPartialSetter<OverwatchState>;
} | null>(null);

interface Props {
  children: React.ReactNode;
}

export const OverwatchStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useStateWithPartialSetter<OverwatchState>({
    tier: OverwatchTier.All,
    position: OverwatchPosition.All,
    queueType: OverwatchQueueType.CompetitiveRole,
  });
  stateWithStorage(
    'global-overwatch-state',
    state,
    setState,
    state,
    'sessionStorage'
  );
  return (
    <OverwatchContext.Provider
      value={{
        state: [state, setState],
      }}
    >
      {children}
    </OverwatchContext.Provider>
  );
};

import '../scss/app.scss';
import '@opgg-internal/opgg-kit/dist/opgg-kit.css';

import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { apolloClient, wsLink } from '../apollo/client';
import AppHead from '../components/AppHead';
import DynamicScripts from '../components/DynamicScripts';
import Layout from '../components/Layout';
import locales from '../locales';
import { GlobalStateProvider } from '../providers/GlobalStateProvider';
import { Locale } from '../types/LocaleTypes';
import { isBrowser, isProduction } from '../utils/misc';
import TagManager from 'react-gtm-module';
import BannedModalContainer from '../components/BannedModalContainer';
import { RecoilRoot } from 'recoil';
import {
  ConfigEnv,
  GNB,
  HouseImageBanner,
  Provider as GNBProvider,
  SashBanner,
  User,
} from '@opgg-internal/opgg-kit';
import { useMember } from '../queries/member';

const STARTS_WITH_GAMES_URL: {
  [key: string]: string;
} = {
  '/lol': 'lol',
  '/lol-arena': 'lol arena',
  '/aram': 'lol aram',
  '/nexus-blitz': 'lol nexus blitz',
  '/tft': 'tft',
  '/valorant': 'valorant',
  '/overwatch': 'overwatch',
  '/palworld': 'palworld',
};

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const { locale = Locale.KO, pathname } = router;
  const [isConnecting, setIsConnecting] = React.useState(false);
  const language = (() => {
    switch (locale) {
      case Locale.EN:
        return 'en_US';
      case Locale.KO:
        return 'ko_KR';
      case Locale.JA:
        return 'ja_JP';
      case Locale.PT:
        return 'pt_BR';
      case Locale.ZH_TW:
        return 'zh_TW';
      case Locale.TH:
        return 'th_TH';
      case Locale.VI:
        return 'vi_VN';
      default:
        return 'en_US';
    }
  })();

  useEffect(() => {
    if (!wsLink) return;

    wsLink.client.on('connected', () => setIsConnecting(true));
    wsLink.client.on('closed', () => setIsConnecting(false));
  }, [wsLink]);

  useEffect(() => {
    const pusher = new Pusher('3187a13c7d831d144efacf90036b502e', {
      cluster: '',
      wsHost: 'opgg-ws-rvwqybshgpwjuczv.op.gg',
      wsPort: 6001,
      forceTLS: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
    });

    pusher.subscribe('opgg-duo');
    pusher.subscribe('simple-opgg-duo');
    pusher.subscribe('unique-opgg-duo');

    TagManager.initialize({ gtmId: 'GTM-5RWPZPG' });
  }, []);

  useEffect(() => {
    if (!isBrowser()) return;

    router.events.on('routeChangeComplete', handleRouteChange);

    function handleRouteChange() {
      if (window.bidrich_start === undefined) {
        return;
      }
      window.bidrich_start();
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    let v = false;
    for (const game in STARTS_WITH_GAMES_URL) {
      if (pathname.startsWith(game)) {
        window.op_page_detail_category = STARTS_WITH_GAMES_URL[game];
        v = true;
        break;
      }
    }
    if (v) return;

    window.op_page_detail_category = null;
  }, [pathname]);

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontSize: 12,
        },
        palette: {
          primary: { main: '#00c0a4' },
          secondary: { main: '#E84057' },
          text: { secondary: '#9e9eb1', disabled: '#515163' },
          background: { default: '#1c1c1f', paper: '#31313c' },
          mode: 'dark',
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundImage: 'none',
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltipArrow: {
                fontSize: 12,
                backgroundColor: '#31313c',
                border: '1px solid #515163',
                '& .MuiTooltip-arrow': {
                  color: '#515163',
                },
              },
            },
          },
        },
      }),
    []
  );

  const gnbOptions = {
    env: (isProduction() ? 'production' : 'development') as ConfigEnv,
    langCodes: ['en_US', 'ko_KR', 'ja_JP', 'pt_BR', 'zh_TW', 'th_TH', 'vi_VN'],
    langCode: language,
    serviceId: 'duo',
    darkMode: true,
    enableLanguage: true,
    enableDarkMode: false,
    enableUser: true,
    customChangeLanguage: (lang: string) => {
      const locale = /zh_tw/i.test(lang)
        ? lang.replace('_', '-')
        : lang.split('_')[0];
      const route = window.location.pathname.replace(
        new RegExp('^/(en|ko|ja|pt|zh-tw|th|vi)', 'i'),
        ''
      );
      try {
        document.cookie =
          'NEXT_LOCALE=' +
          locale +
          '; path=/; max-age=' +
          60 * 60 * 24 * 365 +
          ';';
      } catch {}
      window.location.href = '/' + locale + route + window.location.search;
    },
    onClickLogin: () => {
      window.open(
        'https://member.op.gg/accounts/login?redirect_url=https://' +
          document.domain +
          window.location.pathname +
          window.location.search,
        '_self'
      );
    },
  };

  return (
    <GNBProvider {...gnbOptions}>
      <ApolloProvider client={apolloClient}>
        <RecoilRoot>
          <GlobalStateProvider>
            <ThemeProvider theme={theme}>
              <IntlProvider locale={locale} messages={locales[locale]}>
                <AppHead />
                <GNB />
                <HouseImageBanner />
                <Layout isConnecting={isConnecting}>
                  <Component {...pageProps} />
                  <BannedModalContainer />
                </Layout>

                <DynamicScripts />
              </IntlProvider>
            </ThemeProvider>
          </GlobalStateProvider>
        </RecoilRoot>
      </ApolloProvider>
    </GNBProvider>
  );
};

export default App;

import {
  CheckIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import {
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

import {
  Badge,
  BadgeProps,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReceiptNotification } from '../mutations/notifications';
import { useNotifications } from '../queries/notifications';
import {
  UserNotification,
  UserNotificationTemplate,
} from '../types/notification.type';
import dayjs from 'dayjs';
import { getSubstringAfterUnderscore } from '../utils/string';
import { useRecoilState } from 'recoil';
import { notificationsState } from '../atoms/notification';

type Props = {
  onClose: () => void;
};

type TabType = 'defaults' | 'sanctions';

const isBan = (template: UserNotificationTemplate) =>
  template.startsWith('BAN');

const isWarning = (template: UserNotificationTemplate) =>
  template.startsWith('WARNING');

const StyledBadge = styled(Badge)<{ visible: boolean }>(({ visible }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: -3,
    display: visible ? 'block' : 'none',
  },
}));

const NotificationCenter: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const router = useRouter();
  const [notifications] = useRecoilState(notificationsState);
  const { receipt, loading } = useReceiptNotification();

  const [tabValue, setTabValue] = useState<TabType>('sanctions');
  const tabContent = useMemo(() => {
    if (tabValue === 'defaults') {
      return notifications.defaults;
    } else {
      return [...notifications.bans, ...notifications.warnings].sort(
        (a, b) => dayjs(b.insertedAt).valueOf() - dayjs(a.insertedAt).valueOf()
      );
    }
  }, [tabValue, notifications]);

  const isUnReadTab = useCallback(
    (tab: TabType) => {
      if (tab === 'defaults') {
        return notifications.defaults.some((noti) => !noti.readAt);
      } else {
        return [...notifications.bans, ...notifications.warnings].some(
          (noti) => !noti.readAt
        );
      }
    },
    [notifications]
  );

  const getTemplateIcon = React.useCallback(
    (template: UserNotificationTemplate, read: boolean) => {
      let IconComponent;
      const className = '';
      switch (template) {
        case UserNotificationTemplate.CLAN_ACCEPTED:
        case UserNotificationTemplate.CLASH_ACCEPTED:
          IconComponent = ShieldCheckIcon;
          break;
        case UserNotificationTemplate.CLAN_REJECTED:
        case UserNotificationTemplate.CLASH_REJECTED:
          IconComponent = ShieldExclamationIcon;
          break;
        case UserNotificationTemplate.CLAN_JOIN_REQUEST:
        case UserNotificationTemplate.CLASH_JOIN_REQUEST:
          IconComponent = UserIcon;
          break;
        case UserNotificationTemplate.CLAN_LEAVE:
        case UserNotificationTemplate.CLASH_LEAVE:
          IconComponent = UserIcon;
          break;
        case UserNotificationTemplate.CLASH_INVITE:
          IconComponent = read ? EnvelopeOpenIcon : EnvelopeIcon;
          break;
        default:
          IconComponent = UserIcon;
      }

      if (isBan(template)) {
        return {
          IconComponent: XCircleIcon,
          className: 'text-red-500',
        };
      } else if (isWarning(template)) {
        return {
          IconComponent: ExclamationCircleIcon,
          className: 'text-yellow-500',
        };
      }

      return {
        IconComponent,
        className,
      };
    },
    []
  );

  const getTemplateMessage = React.useCallback(
    (notification: UserNotification) => {
      const { templateType: template, body } = notification;

      if (isBan(template)) {
        return intl.formatMessage(
          {
            id: 'You have been suspended until {date} due to {reason}.',
          },
          {
            date: dayjs(body).format('YYYY-MM-DD'),
            reason: intl.formatMessage({
              id: getSubstringAfterUnderscore(template),
            }),
          }
        );
      }

      if (isWarning(template)) {
        return intl.formatMessage(
          {
            id: 'You have accumulated warnings due to {reason}. Accumulated [{count}] times',
          },
          {
            count: body,
            reason: intl.formatMessage({
              id: getSubstringAfterUnderscore(template),
            }),
          }
        );
      }

      return intl.formatMessage({ id: 'notificationTemplate.' + template });
    },
    []
  );

  return (
    <Box>
      <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)}>
        <Tab
          sx={{
            ':hover': {
              color: 'white',
            },
            textTransform: 'none',
          }}
          value="sanctions"
          label={
            <StyledBadge
              visible={isUnReadTab('sanctions')}
              color="secondary"
              variant="dot"
            >
              <FormattedMessage id={'Sanctions'} />
            </StyledBadge>
          }
        />
        <Tab
          sx={{
            ':hover': {
              color: 'white',
            },
            textTransform: 'none',
          }}
          value="defaults"
          label={
            <StyledBadge
              visible={isUnReadTab('defaults')}
              color="secondary"
              variant="dot"
            >
              <FormattedMessage id={'Notifications'} />
            </StyledBadge>
          }
        />
      </Tabs>
      <List
        sx={{
          width: 320,
          maxWidth: 360,
          maxHeight: 300,
          overflowY: 'scroll',
          bgcolor: '#282830',
        }}
      >
        {tabContent.map((noti) => {
          const {
            IconComponent: TemplateIcon,
            className: teamplateIconClassName,
          } = getTemplateIcon(noti.templateType, !!noti.readAt);

          return (
            <ListItem
              key={`user-noti-${noti.id}`}
              secondaryAction={
                <IconButton
                  color="primary"
                  disabled={!!noti.readAt || loading}
                  onClick={markAsRead(noti)}
                >
                  <CheckIcon className="w-5 h-5" />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton onClick={markAsRead(noti)}>
                <ListItemIcon>
                  <TemplateIcon
                    className={`w-5 h-5 ${teamplateIconClassName}`}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getTemplateMessage(noti)}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        {tabContent.length === 0 && (
          <Typography
            color="text.disabled"
            variant="body2"
            align="center"
            my={4}
          >
            <FormattedMessage id="No notifications have been received." />
          </Typography>
        )}
      </List>
    </Box>
  );

  function markAsRead(notification: UserNotification) {
    return async () => {
      const { id: notificationId, templateType, body } = notification;

      if (!notification.readAt) {
        await receipt({ variables: { notificationId } });
      }

      switch (templateType) {
        case UserNotificationTemplate.CLASH_INVITE:
          if (body) {
            const teamId = body;
            router.push(`/lol/clash/${teamId}`);
          }
          onClose();
          break;

        default:
          onClose();
          break;
      }
    };
  }
};

export default NotificationCenter;

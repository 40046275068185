import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import classNames from 'classnames';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateVisibility = () => {
      setIsVisible(lastScrollY > 0);
      ticking = false;
    };

    const onScroll = () => {
      lastScrollY = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(updateVisibility);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const onClick = () => {
    scrollToTop();
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_SCROLL_TO_TOP,
      },
      GTMEventType.CLICK
    );
  };

  return (
    <button
      onClick={onClick}
      className={classNames(
        'right-4 bottom-24 flex justify-center items-center w-16 h-16 bg-white text-black fixed rounded-[20px] sm:hidden',
        {
          hidden: !isVisible,
        }
      )}
    >
      <ArrowUpwardIcon fontSize={'large'} />
    </button>
  );
};

export default ScrollToTopButton;

import { atom } from 'recoil';
import { defaultNotifications, Notifications } from '../queries/notifications';

export const notificationsState = atom<Notifications>({
  key: 'notificationsState',
  default: {
    defaults: [],
    bans: [],
    warnings: [],
  },
});

import TagManager from 'react-gtm-module';

const op_page_main_category = 'duo';
const op_page_sub_category = 'main';

export enum GTMEventType {
  CLICK = 'op_click',
  SCREEN_VIEW = 'op_screen_view',
  SUBMIT = 'op_submit',
}

export enum GTMEventInfoType {
  CLICK_LIST_MORE = 'click_listmore',
  CLICK_SUMMONER_CERTIFICATION = 'click_summoner_certification',
  CLICK_CANCLE_POST = 'click_cancel_post',
  ERROR_ON_POST = 'view_error_post',
  CLICK_TROLLS_POST = 'click_trolls_post',
  CLICK_SUMMONER_CERTIFICATION_TROLLS = 'click_summoner_verlfy', // 트롤 페이지에서 인증
  CLICK_TROLLS_INFO = 'click_trolls_list',
  CLICK_RSO_SHOW_ONLY_FILTER = 'click_rsoshowonly_filter',
  SUBMIT_EDIT_POST = 'submit_edit_post',
  SUBMIT_DELETE_POST = 'submit_delete_post',
  SUBMIT_POST = 'submit_post',
  SUBMIT_BRINGUP_CLAN = 'submit_bringup_clan',
  SUBMIT_BRINGUP_CLASH = 'submit_bringup_clash',
  CLICK_POSITION_FILTER = 'click_position_filter',
  CLICK_CERTIFIED_FILTER = 'click_certified_filter',
  CLICK_REGION_FILTER = 'click_region_filter',
  CLICK_MODE_FILTER = 'click_mode_filter',
  CLICK_REGISTER_FILTER = 'click_register_filter',
  CLICK_TIER_FILTER = 'click_tier_filter',
  CLICK_NOTIFICATION = 'click_notification',
  CLICK_SCROLL_TO_TOP = 'click_scroll_to_top',
}

const addCommonDataToDataLayer = (
  dataLayer: object,
  eventType: GTMEventType | undefined
) => ({
  ...dataLayer,
  event: eventType,
  op_page_main_category,
  op_page_sub_category,
});

export const dataLayer = (dataLayer: object, eventType: GTMEventType) => {
  TagManager.dataLayer({
    dataLayer: addCommonDataToDataLayer(dataLayer, eventType),
  });
};

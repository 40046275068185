import { useRouter } from 'next/router';
import * as React from 'react';

export type StateActionWithPartial<T> = Partial<T> | ((prevState: T) => T);
export type StateWithPartialSetter<T> = [
  T,
  React.Dispatch<StateActionWithPartial<T>>
];

export type UseStateWithPartialSetter<T> = [
  T,
  React.Dispatch<StateActionWithPartial<T>>
];

type StateDispatcher<T> = React.Dispatch<React.SetStateAction<T>>;

export function useStateWithPartialSetter<T>(
  defaultValue: T
): StateWithPartialSetter<T> {
  const [state, _setState] = React.useState<T>(defaultValue);
  return [state, createPartialSetState(_setState)];
}

export function useMemberRedirect(options?: { destination: 'rso' | 'steam' }) {
  const router = useRouter();

  return () => {
    const { href: pageUrl } = location;

    let url = `https://${process.env.NEXT_PUBLIC_MEMBER_HOST}/accounts/login?redirect_url=${pageUrl}`;

    if (options?.destination) {
      const { destination } = options;
      url = `https://${process.env.NEXT_PUBLIC_MEMBER_HOST}/game-info?open=${
        destination === 'rso' ? 'rsoAttach' : 'steamAttach'
      }&redirect_url=${pageUrl}`;
    }

    router.push(url);
  };
}
export function useForceUpdate() {
  const [, setValue] = React.useState(0);

  return React.useCallback(() => setValue((value) => value + 1), []);
}

function createPartialSetState<T>(setState: StateDispatcher<T>) {
  return (data: StateActionWithPartial<T>) => {
    if (typeof data === 'function') {
      setState(data);
      return;
    }

    setState((prev) => ({
      ...prev,
      ...data,
    }));
  };
}

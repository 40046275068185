export enum ValorantAgentClass {
  All = 'ALL',
  Controller = 'CONTROLLER',
  Duelist = 'DUELIST',
  Initiator = 'INITIATOR',
  Sentinel = 'SENTINEL',
}

export interface ValorantAgent {
  id: string;
  name: string;
  wr: string;
  games: number;
  kda: string;
  kdaAvg: number;
  scorePoint: string;
  characterId?: string;
}

import React from 'react';
import {
  StateWithPartialSetter,
  useStateWithPartialSetter,
} from '../hooks/utils';
import { Nullable } from '../types/UtilTypes';
import { ValorantAgentClass } from '../types/valorant/agent.type';
import { ValorantQueueType } from '../types/valorant/partner.type';
import { ValorantTier } from '../types/valorant/player.type';
import stateWithStorage from '../utils/stateWithStorage';

export type ValorantState = {
  tier: ValorantTier;
  queueType: ValorantQueueType;
  agentClass: Nullable<ValorantAgentClass>;
  isAuth: boolean;
};

export const ValorantContext = React.createContext<{
  state: StateWithPartialSetter<ValorantState>;
} | null>(null);

interface Props {
  children: React.ReactNode;
}

export const ValorantStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useStateWithPartialSetter<ValorantState>({
    tier: ValorantTier.All,
    queueType: ValorantQueueType.Competitive,
    agentClass: ValorantAgentClass.All,
    isAuth: false,
  });
  stateWithStorage(
    'global-valorant-state',
    state,
    setState,
    state,
    'sessionStorage'
  );
  return (
    <ValorantContext.Provider
      value={{
        state: [state, setState],
      }}
    >
      {children}
    </ValorantContext.Provider>
  );
};

import { IPartner } from '../PartnerTypes';
import { OverwatchPlayer } from './player.type';

export interface OverwatchPartner extends IPartner {
  position: OverwatchPosition;
  queueType: OverwatchQueueType;
  player: OverwatchPlayer;
}

export enum OverwatchQueueType {
  CompetitiveRole = 'COMPETITIVE_ROLE',
  CompetitiveOpen = 'COMPETITIVE_OPEN',
  QuickPlay = 'QUICK_PLAY',
}

export enum OverwatchPosition {
  All = 'ALL',
  Tank = 'TANK',
  Damage = 'DAMAGE',
  Support = 'SUPPORT',
}

export function isOverwatchPartner(
  partner: IPartner
): partner is OverwatchPartner {
  return 'player' in partner;
}

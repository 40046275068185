import React from 'react';
import {
  StateWithPartialSetter,
  useStateWithPartialSetter,
} from '../hooks/utils';
import stateWithStorage from '../utils/stateWithStorage';
import { Tier } from '../types/lol/summoner.type';
import { Nullable } from '../types/UtilTypes';
import { NexusPosition } from '../types/lol/partner.type';

export type NexusState = {
  tier: Tier;
  isAuth: boolean;
  position: Nullable<NexusPosition>;
};

export const NexusContext = React.createContext<{
  state: StateWithPartialSetter<NexusState>;
} | null>(null);

interface Props {
  children: React.ReactNode;
}

export const NexusStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useStateWithPartialSetter<NexusState>({
    tier: Tier.ALL,
    isAuth: false,
    position: NexusPosition.ALL,
  });
  stateWithStorage(
    'global-nexus-state',
    state,
    setState,
    state,
    'sessionStorage'
  );
  return (
    <NexusContext.Provider
      value={{
        state: [state, setState],
      }}
    >
      {children}
    </NexusContext.Provider>
  );
};

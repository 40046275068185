import { gql, useQuery } from '@apollo/client';
import { useUser } from '../hooks/user';
import { CertifiedSummoner, Region } from '../types/lol/summoner.type';

export function useCertifiedSummoners() {
  const { isSignedIn } = useUser();
  const { data, error, loading } = useQuery<{
    certifiedSummoners: CertifiedSummoner[];
  }>(GET_CERTIFIED_SUMMONERS, {
    skip: !isSignedIn,
  });

  return {
    summoners: data?.certifiedSummoners ?? null,
    error,
    loading,
  };
}

const GET_CERTIFIED_SUMMONERS = gql`
  query getCertifiedSummoners {
    certifiedSummoners {
      name
      gameName
      tagLine
      profileImageUrl
    }
  }
`;

export const useRecentlyGameSummoners = (region: Region) => {
  const { data, error, loading } = useQuery<{
    recentlyGameSummoners: CertifiedSummoner[];
  }>(GET_RECENTLY_GAME_SUMMONERS, {
    variables: {
      region,
    },
  });

  return {
    summoners: data?.recentlyGameSummoners ?? null,
    error,
    loading,
  };
};

const GET_RECENTLY_GAME_SUMMONERS = gql`
  query getRecentlyGameSummoners($region: String!) {
    recentlyGameSummoners(region: $region) {
      name
      gameName
      tagLine
      profileImageUrl
    }
  }
`;

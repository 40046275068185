import React from 'react';
import { useClickAway, useKey } from 'react-use';

export function useModal(): {
  visible: boolean;
  open: () => void;
  close: () => void;
} {
  const [visible, setVisible] = React.useState(false);

  return React.useMemo(() => {
    function open(): void {
      setVisible(true);
    }

    function close(): void {
      setVisible(false);
    }

    return { open, close, visible };
  }, [visible]);
}

export function useCancel(
  ref: React.RefObject<HTMLElement | null>,
  onClickAway: () => void
): void {
  useClickAway(ref, (e) => {
    if (e.target !== ref.current?.parentElement) return;

    onClickAway();
  });
  useKey('Escape', onClickAway);
}

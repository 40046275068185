import { GameResult } from '../PartnerTypes';
import { Region } from '../lol/summoner.type';

export interface TFTSummoner {
  id: number;
  name: string;
  gameName?: string;
  tagLine?: string;
  profileImageUrl: string;
  tier: string;
  wins: number;
  losses: number;
  rankAverage: number;
  traits: TFTSummonerTrait[];
  region: TFTRegion;
  insertedAt: Date;
}

export interface TFTSummonerTrait {
  id: string;
  name: string;
  rankAvg: number;
  games: number;
  imageUrl: string;
}

export enum TFTTier {
  ALL = 'ALL',
  UNRANKED = 'UNRANKED',
  IRON = 'IRON',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
}

export enum TFTRegion {
  NA = 'na',
  EUW = 'euw',
  EUNE = 'eune',
  OCE = 'oce',
  KR = 'kr',
  JP = 'jp',
  BR = 'br',
  LAS = 'las',
  LAN = 'lan',
  RU = 'ru',
  TR = 'tr',
  SG = 'sg',
  PH = 'ph',
  TW = 'tw',
  VN = 'vn',
  TH = 'th',
}

export const fromLOLRegion = (region: Region): TFTRegion => {
  return (Object.entries(TFTRegion).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', TFTRegion.KR])[1];
};
export const fromTFTRegion = (region: TFTRegion): Region => {
  return (Object.entries(Region).find(([key, value]) => {
    if (value.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      return value;
    }
  }) ?? ['KR', Region.KR])[1];
};

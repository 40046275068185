export function ReplaceDoubleFinalConsonants(variant: string) {
  /**
   * 초성
   * ex) 가(ㄱ), 날(ㄴ) 닭(ㄷ)
   * 0x3131, 0x3132, 0x3134, 0x3137, 0x3138,
   * 0x3139, 0x3141, 0x3142, 0x3143, 0x3145,
   * 0x3146, 0x3147, 0x3148, 0x3149, 0x314a,
   * 0x314b, 0x314c, 0x314d, 0x314e
   *
   * 중성
   * ex) 가(ㅏ), 야(ㅑ), 뺨(ㅑ)
   * 0x314f, 0x3150, 0x3151, 0x3152, 0x3153,
   * 0x3154, 0x3155, 0x3156, 0x3157, 0x3158,
   * 0x3159, 0x315a, 0x315b, 0x315c, 0x315d,
   * 0x315e, 0x315f, 0x3160, 0x3161, 0x3162,
   * 0x3163
   *
   * 종성
   * ex) 가(없음), 갈(ㄹ) 천(ㄴ)
   * 0x0000, 0x3131, 0x3132, 0x3133, 0x3134,
   * 0x3135, 0x3136, 0x3137, 0x3139, 0x313a,
   * 0x313b, 0x313c, 0x313d, 0x313e, 0x313f,
   * 0x3140, 0x3141, 0x3142, 0x3144, 0x3145,
   * 0x3146, 0x3147, 0x3148, 0x314a, 0x314b,
   * 0x314c, 0x314d, 0x314e
   */
  const JONGSUNGKEY = [
    'ㄱ',
    'ㄱㄱ',
    'ㄱㅅ',
    'ㄴ',
    'ㄴㅈ',
    'ㄴㅎ',
    'ㄷ',
    'ㄷㄷ',
    'ㄹ',
    'ㄹㄱ',
    'ㄹㅁ',
    'ㄹㅂ',
    'ㄹㅅ',
    'ㄹㅌ',
    'ㄹㅍ',
    'ㄹㅎ',
    'ㅁ',
    'ㅂ',
    'ㅂㅂ',
    'ㅂㅅ',
    'ㅅ',
    'ㅅㅅ',
    'ㅇ',
    'ㅈ',
    'ㅈㅈ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
  ];

  let result = '';

  for (let i = 0; i < variant.length; i++) {
    let n = variant.charCodeAt(i);

    // 유저가 종성만 입력했을 때 분리 ex) 'ㄲ'=>'ㄱㄱ', 'ㄳ'=>'ㄱㅅ'
    if (
      // 종성 범위
      n >= 0x3131 && // 12593, ㄱ
      n <= 0x314e && // 12622, ㅎ
      // 뽀삐, 쓰레쉬 등을 검색할 때 'ㅃ' => 'ㅂㅂ', 'ㅆ' => ㅅㅅ 등으로 분리되지 않도록 겹자음(ㄲ, ㄸ, ㅃ, ㅆ, ㅉ) 예외
      n !== 12611 && // ㅃ
      n !== 12617 && // ㅉ
      n !== 12600 && // ㄸ
      n !== 12594 && // ㄲ
      n !== 12614 // ㅆ
    ) {
      n -= 0x3131;
      result += JONGSUNGKEY[n];
    } else {
      result += String.fromCharCode(n);
    }
  }

  return result;
}

export function getSubstringAfterUnderscore(str: string) {
  const index = str.indexOf('_');
  if (index === -1) return '';
  return str.substring(index + 1);
}

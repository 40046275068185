import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useSelectedGame } from '../lib/games';
import React, { useEffect } from 'react';
import { useMember } from '../queries/member';
import { useCertifiedSummoners } from '../queries/summoners';
import { isNullArray } from '../utils/array';
import { useRouter } from 'next/router';
import { Locale } from '../types/LocaleTypes';

const AppHead: React.FC = () => {
  const selectedGame = useSelectedGame();
  const { user } = useMember();
  const intl = useIntl();
  const { summoners: certifiedSummoners } = useCertifiedSummoners();
  const routeInfo = useRouter();

  useEffect(() => {
    window.user_member_id = user?.mid ?? null;
  }, [user?.mid]);

  useEffect(() => {
    window.user_member_plan_ids =
      user?.planIds && user.planIds.length !== 0
        ? `[${user.planIds.map((id) => `${id}`).toString()}]`
        : null;
  }, [user?.planIds]);

  useEffect(() => {
    window.op_page_params = JSON.stringify({
      is_rso: !isNullArray(certifiedSummoners),
      is_subscribed: !!user?.adFree,
    });
  }, [certifiedSummoners, user?.adFree]);

  useEffect(() => {
    const changeGTMLanguage = (langCode: string) => {
      return langCode === 'default'
        ? 'en'
        : langCode
        ? langCode == 'zh-TW'
          ? 'zh'
          : langCode
        : 'en';
    };
    const head = document.head;
    const script = document.createElement('script');

    script.id = 'google-analytics-global-variables';
    script.type = 'text/javascript';

    script.innerHTML = `
      var op_page_main_category="duo"; 
      var op_page_sub_category="main";
      var op_event_value=null;
      var user_member_id=null;
      var user_member_plan_ids=null;
      var op_page_params=null;
      var op_page_detail_category=null;
      var op_page_language="${changeGTMLanguage(
        routeInfo.locale ? routeInfo.locale : Locale.KO
      )}";
		`;
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [routeInfo.locale]);

  const metaDataInfo = getMetaData(routeInfo.pathname);
  return (
    <Head>
      <title>
        {intl.formatMessage(
          {
            id: metaDataInfo['title'],
          },
          {
            game: intl.formatMessage({ id: selectedGame }),
          }
        )}
      </title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="DUO, 오피지지, 듀오" />
      <meta
        name="description"
        content={intl.formatMessage(
          {
            id: metaDataInfo['desc'],
          },
          {
            game: intl.formatMessage({ id: selectedGame }),
          }
        )}
      />
      <meta
        property="og:description"
        content={intl.formatMessage(
          {
            id: metaDataInfo['desc'],
          },
          {
            game: intl.formatMessage({ id: selectedGame }),
          }
        )}
      />
    </Head>
  );
};

const getMetaData = (pathname: string) => {
  let title, desc;
  if (pathname === '/lol/trollbox') {
    title = 'troll {game} title';
    desc = 'troll {game} desc';
  } else if (pathname === '/lol/clans') {
    title = 'clan {game} title';
    desc = 'clan desc';
  } else if (pathname === '/lol/clash') {
    title = 'clash {game} title';
    desc = 'clash desc';
  } else {
    title = 'partner {game} title';
    desc = 'partner {game} desc';
  }

  return {
    title,
    desc,
  };
};

export default AppHead;

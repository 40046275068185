import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  className?: string;
};

const DiscordBanner: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const locale = intl.locale === 'ko' ? 'ko' : 'en';

  return (
    <div className={classNames(className)}>
      <a href="https://discord.gg/SKY3y75qf9" rel="noopener">
        <img
          src={`//opgg-desktop-data.akamaized.net/download/duo/images/banners/banner-discord-${locale}.png`}
          width={300}
          height="auto"
          alt="Discord banner"
          className="rounded-sm"
        />
      </a>
    </div>
  );
};

export default DiscordBanner;

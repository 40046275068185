import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { graphqlWsUrl, graphqlHttpUrl, isBrowser } from '../utils/misc';
import { getToken } from '../utils/application';

const uuid = isBrowser() ? getUUID() : '';

const httpLink = createUploadLink({
  uri: graphqlHttpUrl(),
  credentials: 'same-origin',
});

const wsLink = isBrowser()
  ? new GraphQLWsLink(
      createClient({
        url: graphqlWsUrl(),
        connectionParams: () => ({
          uuid,
          token: getToken(),
        }),
      })
    )
  : undefined;

const splitLink = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    )
  : httpLink;

const paginationOptions = {
  keyArgs: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge(existing = [], incoming = [], { args }: any) {
    if (args && args?.page === 0) {
      return incoming;
    }

    return existing.concat(incoming);
  },
};

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          partners: paginationOptions,
          clans: paginationOptions,
        },
      },
    },
  }),
});

function getUUID() {
  const UUID_KEY = '_uuid';
  const uuid = Cookies.get(UUID_KEY);

  if (!uuid) {
    const _uuid = uuidv4();
    Cookies.set(UUID_KEY, _uuid);
    return _uuid;
  }
  return uuid;
}

export { apolloClient, wsLink };

import { Tier } from './lol/summoner.type';
import { Member } from './MemberTypes';
import { Nullable } from './UtilTypes';

export enum ExternalUrlType {
  DISCORD = 'discord_url',
  KAKAOTALK = 'kakao_url',
}

export interface IExternalUrlInfo {
  type: ExternalUrlType;
  url: string;
}

export interface ExternalUrlObject {
  discordUrl: Nullable<string>;
  kakaoUrl: Nullable<string>;
}

export interface IClan {
  id: string;
  name: string;
  externalUrls: IExternalUrlInfo[];
  content: string;
  contentImageUrl?: string;
  region: string;
  insertedAt: string;
  userId: string;
  summonerName: Nullable<string>;
  tier: Nullable<Tier>;
  memberCount: number;
  myStatus?: string;
}

export interface IClanFormState {
  id: Nullable<string>;
  name: Nullable<string>;
  discordUrl: Nullable<string>;
  kakaoUrl: Nullable<string>;
  content: Nullable<string>;
  contentImage: Nullable<IContentImage>;
  region: Nullable<string>;
  contentImageUrl?: string;
  summonerName: Nullable<string>;
  tier: Nullable<string>;
}

export interface IContentImage {
  file: Nullable<File>;
  width: number;
  height: number;
  preview: Nullable<string>;
}

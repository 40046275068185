import { IPartner } from '../PartnerTypes';
import { TFTSummoner } from './summoner.type';

export interface TFTPartner extends IPartner {
  queueType: TFTQueueType;
  summoner: TFTSummoner;
}

export enum TFTQueueType {
  PAIRS = 'PAIRS',
  STANDARD = 'STANDARD',
  TURBO = 'TURBO',
  RANK = 'RANK',
  FORTUNES_FAVOR = 'LNY',
}

import { gql, useApolloClient } from '@apollo/client';
import React from 'react';

export const SUBSCRIPTION_CONNECTIONS = gql`
  subscription {
    connectionUpdated
  }
`;

export function useSubscriptionConnects(
  dispatch: React.Dispatch<number>
): void {
  const apolloClient = useApolloClient();

  React.useEffect(() => {
    const observer = apolloClient.subscribe<{
      connectionUpdated: number;
    }>({
      query: SUBSCRIPTION_CONNECTIONS,
    });

    const subscription = observer.subscribe(({ data }) => {
      if (!data?.connectionUpdated) return;
      dispatch(data.connectionUpdated);
    });

    return () => subscription.unsubscribe();
  }, []);
}

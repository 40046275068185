import { gql, useMutation } from '@apollo/client';

export function useReceiptNotification() {
  const [receipt, { ...rest }] = useMutation<
    { receiptNotification: string },
    { notificationId: number }
  >(RECEIPT_NOTIFICATION);

  return {
    receipt,
    ...rest,
  };
}

export const RECEIPT_NOTIFICATION = gql`
  mutation ReceiptNotification($notificationId: ID!) {
    receiptNotification(id: $notificationId) {
      id
      readAt
    }
  }
`;

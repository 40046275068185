import classNames from 'classnames';
import React from 'react';
import { useSelectedGame } from '../lib/games';
import { SupportedGame } from '../types/game.type';
import Container from './Container';
import dayjs from 'dayjs';
import { Locale } from '../types/LocaleTypes';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
}

const navList: {
  title: string;
  list: {
    name: string;
    href: string;
    krHref?: string;
    className?: string;
    game?: boolean;
    formattedMessage?: boolean;
  }[];
}[] = [
  {
    title: 'OP.GG',
    list: [
      {
        name: 'About OP.GG',
        href: 'https://www.op.gg/about',
        formattedMessage: false,
      },
      {
        name: 'Company',
        href: 'https://log.op.gg/company_en/',
        krHref: 'https://log.op.gg/about-company/',
        formattedMessage: false,
      },
      {
        name: 'Blog',
        href: 'https://log.op.gg/',
        formattedMessage: false,
      },
      {
        name: 'Logo History',
        href: 'https://www.op.gg/logos',
      },
    ],
  },
  {
    title: 'Products',
    list: [
      {
        name: 'League of Legends',
        href: 'https://op.gg/',
        game: true,
      },
      {
        name: 'TFT',
        href: 'https://tft.op.gg/',
        game: true,
      },
      {
        name: 'Valorant',
        href: 'https://valorant.op.gg/',
        game: true,
      },
      {
        name: 'PUBG',
        href: 'https://pubg.op.gg/',
        game: true,
      },
      {
        name: 'Overwatch2',
        href: 'https://overwatch.op.gg/',
        game: true,
      },
      {
        name: 'Esports',
        href: 'https://esports.op.gg/',
        game: true,
      },
      {
        name: 'TALK',
        href: 'https://talk.op.gg/',
        game: true,
      },
      {
        name: 'Gigs',
        href: 'https://gigs.op.gg/',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'Duo',
        href: 'https://duo.op.gg/',
        game: true,
      },
    ],
  },
  {
    title: 'Apps',
    list: [
      {
        name: 'OP.GG for Desktop',
        href: 'https://op.gg/desktop/',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'OP.GG Palword for Desktop',
        href: 'https://pal.op.gg/',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'OP.GG Stats for Android',
        href: 'https://play.google.com/store/apps/details?id=gg.op.lol.android&referrer=utm_source%3Dadblock%26utm_medium%3Dbanner',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'OP.GG Stats for iOS',
        href: 'https://itunes.apple.com/kr/app/op-gg-%EC%98%A4%ED%94%BC%EC%A7%80%EC%A7%80/id605722237?mt=8',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'OP.GG TFT for Android',
        href: 'https://play.google.com/store/apps/details?id=gg.op.tft',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'OP.GG TFT for iOS',
        href: 'https://apps.apple.com/kr/app/allt/id6448737621',
        game: true,
        formattedMessage: false,
      },
      {
        name: 'TalkG for Android',
        href: 'https://play.google.com/store/apps/details?id=gg.op.talk',
        krHref:
          'https://play.google.com/store/apps/details?id=gg.op.talk&hl=ko-KR',
        formattedMessage: false,
      },
      {
        name: 'TalkG for iOS',
        href: 'https://apps.apple.com/kr/app/%ED%86%A1%EC%A7%80-talkg-%EA%B2%8C%EC%9E%84%EA%B3%BC-%EC%9D%BC%EC%83%81%EC%9D%84-%EC%97%B0%EA%B2%B0%ED%95%98%EB%8A%94-%EC%BB%A4%EB%AE%A4%EB%8B%88%ED%8B%B0/id6475250505',
        formattedMessage: false,
      },
    ],
  },
  {
    title: 'Resources',
    list: [
      {
        name: 'Privacy Policy',
        href: 'https://www.op.gg/policies/privacy',
        className: 'font-bold',
      },
      {
        name: 'Terms of Use',
        href: 'https://www.op.gg/policies/agreement',
      },
      {
        name: 'Help',
        href: 'https://opgg.helpscoutdocs.com/collection/1-opgg',
        krHref: 'https://opggkr.helpscoutdocs.com/collection/121-opgg',
      },
      {
        name: 'Email Inquiry',
        href: 'mailto:service@op.gg',
      },
    ],
  },
  {
    title: 'More',
    list: [
      {
        name: 'Business',
        href: 'mailto:contact@op.gg',
      },
      {
        name: 'Advertise',
        href: 'https://opgg.notion.site/Advertising-on-OP-GG-47e02da6b7eb438288730c92c14ef8f0',
        krHref: 'https://opgg.notion.site/d69942ba46e1484b8d0259282ae257e3',
      },
      {
        name: 'Recruitment',
        href: 'https://opgg.team/?lang=en',
        krHref: 'https://opgg.team/',
      },
    ],
  },
];

const Footer: React.FC<Props> = ({ className }) => {
  const selectedGame = useSelectedGame();
  const { locale = Locale.KO } = useRouter();

  return (
    <section
      id="duo-footer"
      className={classNames(
        'pt-24 pb-16 text-2xs lg:text-xs text-gray-600',
        className
      )}
    >
      <Container>
        <div className={'footer-main'}>
          <strong className="logo">
            <a href="/">
              <img
                src="https://s-lol-web.op.gg/images/icon/icon-opgglogo-white.svg?v=1691054379286"
                width="99"
                alt="OP.GG"
                height="24"
                loading="lazy"
              />
            </a>
          </strong>
          <div className="nav-list">
            {navList.map((nav) => (
              <div key={nav.title}>
                <strong className="title">{nav.title}</strong>
                <nav>
                  {nav.list.map(
                    ({
                      name,
                      href,
                      krHref,
                      className,
                      game,
                      formattedMessage = true,
                    }) => (
                      <a
                        href={locale === Locale.KO ? krHref ?? href : href}
                        target="_blank"
                        rel="noreferrer"
                        className={className}
                        key={name}
                      >
                        {formattedMessage ? (
                          <FormattedMessage id={name} />
                        ) : (
                          name
                        )}
                        {game && (
                          <img
                            src="https://s-lol-web.op.gg/images/icon/icon-game-white.svg?v=1691054379286"
                            width="16"
                            alt="League of Legends"
                            className="game"
                            height="16"
                          />
                        )}
                      </a>
                    )
                  )}
                </nav>
              </div>
            ))}
          </div>
        </div>
        <div className="footer-sns">
          <small>
            {selectedGame === SupportedGame.Overwatch ? (
              `© 2012-${dayjs().get('y')} OP.GG. Data based on Overwatch.`
            ) : (
              <>
                © 2012-{dayjs().get('y')} OP.GG.{' '}
                <span className="font-gilroy font-bold">DUO</span>{' '}
                {`isn't endorsed by Riot Games and doesn't reflect the views or
            opinions of Riot Games or anyone officially involved in producing or
            managing Riot Games properties. Riot Games, and all associated
            properties are trademarks or registered trademarks of Riot Games, Inc.`}
              </>
            )}
            {locale === Locale.KO && (
              <div>
                주식회사 오피지지 (OP.GG) | 통신판매업신고 :
                제2019-서울강남-01973호 | 사업자등록번호 : 295-88-00023 | 대표자
                : 최상락
                <br />
                서울특별시 강남구 테헤란로 507, 1층, 2층(삼성동, WeWork빌딩) |
                전화 : 02-455-9903 (평일 09:00 ~ 18:00) | 이메일 : service@op.gg
              </div>
            )}
          </small>
          <nav className="sns">
            <a
              target="_blank"
              href="https://www.instagram.com/opgg_official"
              rel="noreferrer"
            >
              <img
                src="https://s-lol-web.op.gg/images/icon/icon-logo-instagram.svg?v=1691054379286"
                width="24"
                alt="instagram account"
                height="24"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@opgg_official"
              rel="noreferrer"
            >
              <img
                src="https://s-lol-web.op.gg/images/icon/icon-logo-youtube.svg?v=1691054379286"
                width="24"
                alt="twitter account"
                height="24"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/opgg_official"
              rel="noreferrer"
            >
              <img
                src="https://s-lol-web.op.gg/images/icon/icon-logo-x.svg?v=1691054379286"
                width="24"
                alt="twitter account"
                height="24"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/opgg.official/"
              rel="noreferrer"
            >
              <img
                src="https://s-lol-web.op.gg/images/icon/icon-logo-facebook.svg?v=1691054379286"
                width="24"
                alt="facebook account"
                height="24"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://www.overwolf.com/oneapp/opgg-electron-app"
              rel="noreferrer"
            >
              <img
                src="https://s-lol-web.op.gg/static/images/icon/logo/icon-logo-overwolf.svg?v=1691054379286"
                width="24"
                alt="Overwolf"
                height="24"
                loading="lazy"
              />
            </a>
          </nav>
        </div>
      </Container>
    </section>
  );
};

export default Footer;

import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: React.HTMLAttributes<HTMLImageElement>['className'];
};

const Spinner: React.FC<Props> = ({ className }) => {
  return (
    <img
      src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/loading.svg"
      className={classNames('animate-spin h-4 w-4', className)}
    />
  );
};

export default Spinner;

import gql from 'graphql-tag';

export const CORE_NOTIFICATION = gql`
  fragment CoreNotification on Notification {
    id
    templateType
    body
    senderId
    receiverId
    readAt
    insertedAt
  }
`;
